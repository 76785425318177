import usePopUp from '@hooks/usePopUp';
import React, { useCallback } from 'react';
import RegisterPopup from '../components/RegisterPopup';

type Props = {
  onConfirmClicked: () => void;
};

const RegisterPopupContainer = ({ onConfirmClicked }: Props) => {
  const { __hidePopupFromHooks } = usePopUp();

  const onCancelClicked = useCallback(() => {
    __hidePopupFromHooks();
  }, [__hidePopupFromHooks]);

  return (
    <RegisterPopup
      onCancelClicked={onCancelClicked}
      onRegisterClicked={onConfirmClicked}
    />
  );
};

export default RegisterPopupContainer;
