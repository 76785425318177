import { BasicApiRespose } from '@typedef/lib/api/api.types';
import axios from 'axios';

const API_ORIGIN =
  process.env.NODE_ENV === 'development'
    ? 'https://sgd-api.wimcorp.dev/api/v1'
    : 'https://sgd-api.wimcorp.dev/api/v1';

export const apiRoute = {
  auth: {
    login: `${API_ORIGIN}/auth/login`,
    logout: `${API_ORIGIN}/auth/logout`,
    refresh: `${API_ORIGIN}/auth/refresh`,
    email: `${API_ORIGIN}/auth/email`,
    verify: `${API_ORIGIN}/auth/email`,
    delete: `${API_ORIGIN}/auth`,
  },
  user: {
    signup: `${API_ORIGIN}/users`,
    update: `${API_ORIGIN}/users`,
    findpassword: `${API_ORIGIN}/users/password`,
    profile: `${API_ORIGIN}/users/profile`,
    duplication: `${API_ORIGIN}/users/duplication`,
  },
  reservation: {
    getHistory: `${API_ORIGIN}/reservation/all`,
    register: `${API_ORIGIN}/reservation`,
    getList: `${API_ORIGIN}/reservation`,
  },
};

export function requestGet<T>(
  url: string,
  header: object,
): Promise<BasicApiRespose<T>> {
  return axios
    .get(url, {
      headers: {
        'Content-Type': 'application/json',
        ...header,
      },
    })
    .then(async (res) => {
      const data = await res.data;
      return {
        data: data as T,
        config: {
          statusCode: res.status,
        },
      };
    });
}

export function requestPost<T>(
  url: string,
  header: object,
  body: object,
): Promise<BasicApiRespose<T>> {
  return axios
    .post(url, body, { headers: { ...header } })
    .then(async (res) => {
      const data = await res.data;

      return {
        data: data as T,
        config: {
          statusCode: res.status,
        },
      };
    })
    .catch((error) => {
      return {
        data: {} as T,
        config: {
          statusCode: -1,
          message: error.response.data.message,
        },
      };
    });
}

export function requestPatch<T>(
  url: string,
  header: object,
  body: object,
): Promise<BasicApiRespose<T>> {
  return axios
    .patch(url, body, { headers: { ...header } })
    .then(async (res) => {
      const data = await res.data;

      return {
        data: data as T,
        config: {
          statusCode: res.status,
        },
      };
    })
    .catch((reason) => {
      return {
        data: reason as T,
        config: {
          statusCode: -1,
        },
      };
    });
}

export function requestDelete<T>(
  url: string,
  header: object,
): Promise<BasicApiRespose<T>> {
  return axios
    .delete(url, {
      headers: {
        'Content-Type': 'application/json',
        ...header,
      },
    })
    .then(async (res) => {
      const data = await res.data;

      return {
        data: data as T,
        config: {
          statusCode: res.status,
        },
      };
    });
}
