import images from 'assets/images';
import React, { RefObject } from 'react';
import './styles/login.styles.css';

type Props = {
  emailInputRef: RefObject<HTMLInputElement>;
  pwInputRef: RefObject<HTMLInputElement>;
  setNeedEmailCheck: React.Dispatch<React.SetStateAction<boolean>>;
  needEmailCheck: boolean;
  isKnuEmail: boolean;
  loginFailed: boolean;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  setPassword: React.Dispatch<React.SetStateAction<string>>;
  onLoginBtnClicked: () => void;
  onSignUpClicked: () => void;
  onFindPasswordClicked: () => void;
};

const Login = ({
  emailInputRef,
  pwInputRef,
  setNeedEmailCheck,
  needEmailCheck,
  isKnuEmail,
  loginFailed,
  setEmail,
  setPassword,
  onLoginBtnClicked,
  onSignUpClicked,
  onFindPasswordClicked,
}: Props) => {
  return (
    <div className='login-root'>
      <div className='login-logo-section'>
        <img
          className='login-logo-icon'
          src={images.login.logo_icon}
          alt='Login Logo Icon'
        />

        <span>사회과학대학교</span>
        <span>온라인예약시스템</span>
      </div>

      <div className='login-input-section'>
        <div className='input-wrapper'>
          <img
            className='input-logo'
            src={images.login.email_input}
            alt='Login Email Input Logo'
          />
          <input
            ref={emailInputRef}
            className='custom-input login-input'
            type='email'
            onChange={(e) => {
              setEmail(e.target.value);
              setNeedEmailCheck(true);
            }}
            placeholder='E-mail'
          />
          {needEmailCheck && !isKnuEmail && (
            <img
              className='input-error-logo'
              src={images.common.error}
              alt='Login Email Input Error Logo'
            />
          )}
          {needEmailCheck && !isKnuEmail && (
            <span className='alert-message'>
              경북대학교 웹메일 형식이 아닙니다
            </span>
          )}
        </div>
        <div className='input-wrapper'>
          <img
            className='input-logo'
            src={images.login.password_input}
            alt='Login Password Input Logo'
          />
          <input
            ref={pwInputRef}
            className='custom-input login-input'
            type='password'
            onChange={(e) => setPassword(e.target.value)}
            placeholder='Password'
          />
          {loginFailed && (
            <img
              className='input-error-logo'
              src={images.common.error}
              alt='Login Email Input Error Logo'
            />
          )}
          {loginFailed && (
            <span className='alert-message'>
              아이디 또는 패스워드가 다릅니다
            </span>
          )}
        </div>
        <button className='login-button' onClick={onLoginBtnClicked}>
          로그인
        </button>
        <div className='login-route-section'>
          <button onClick={onSignUpClicked}>
            <span>회원가입 하기</span>
            <img src={images.common.right_arrow} alt='Right Arrow' />
          </button>
          <button onClick={onFindPasswordClicked}>
            <span>비밀번호 찾기</span>
            <img src={images.common.right_arrow} alt='Right Arrow' />
          </button>
        </div>
      </div>

      <div className='bottom-logo-section'>
        <span>Provided By WIM</span>
        <img src={images.common.wim_logo} alt='WIM Logo' />
      </div>
    </div>
  );
};

export default Login;
