import React from 'react';
import '../styles/date.popup.css';

type Props = {
  title: string;
  content: string;
  onConfirmClicked: () => void;
};

const DateAlertPopup = ({ title, content, onConfirmClicked }: Props) => {
  return (
    <div className='date-popup-root'>
      <div className='popup-header'>
        <span>{title}</span>
      </div>
      <div className='popup-content'>
        <span>{content}</span>
      </div>

      <div className='popup-footer'>
        <button className='change-button' onClick={onConfirmClicked}>
          확인
        </button>
      </div>
    </div>
  );
};

export default DateAlertPopup;
