import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Policy from '../components/Policy';

const PolicyContainer = () => {
  const navigate = useNavigate();
  const [isAgree, setIsAgree] = useState(false);

  const onAgreeClicked = useCallback(() => {
    setIsAgree((prev) => !prev);
  }, [isAgree]);

  const onNextClicked = useCallback(() => {
    navigate('/signUp');
  }, [navigate]);

  const onPolicyClicked = useCallback(() => {
    navigate('./personal');
  }, [navigate]);

  return (
    <Policy
      onPolicyClicked={onPolicyClicked}
      isAgree={isAgree}
      onAgreeClicked={onAgreeClicked}
      onNextClicked={onNextClicked}
    />
  );
};

export default PolicyContainer;
