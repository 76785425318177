import React from 'react';
import '../styles/exit.popup.styles.css';

type Props = {
  onCancelClicked: () => void;
  onExitClicked: () => void;
};

const ExitPopup = ({ onCancelClicked, onExitClicked }: Props) => {
  return (
    <div className='exit-popup-root'>
      <div className='popup-header'>
        <span>종료</span>
      </div>
      <div className='popup-content'>
        <span>종료 하시겠습니까?</span>
      </div>

      <div className='popup-footer'>
        <button className='cancel-button' onClick={onCancelClicked}>
          취소
        </button>
        <button className='change-button' onClick={onExitClicked}>
          확인
        </button>
      </div>
    </div>
  );
};

export default ExitPopup;
