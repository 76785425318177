import {
  AUTO_LOGIN_MESSAGE_TYPE,
  LOGOUT_MESSAGE_TYPES,
  ON_BACK_PRESSED_MESSAGE_TYPES,
  ON_DEVICE_INFO_MESSAGE_TYPES,
  ON_EXIT_APP_MESSAEG_TYPES,
  ON_LOGIN_SUCCESS_MESSAGE_TYPES,
  ON_UPDATE_PASSWORD_TYPES,
} from './modules/messageTypes';

export const autoLoginMessage = (
  accessToken: string,
  refreshToken: string,
) => ({
  type: AUTO_LOGIN_MESSAGE_TYPE,
  payload: {
    accessToken,
    refreshToken,
  },
});

export const onBackPressedMessage = () => ({
  type: ON_BACK_PRESSED_MESSAGE_TYPES,
  payload: {},
});

export const onExitAppMessage = () => ({
  type: ON_EXIT_APP_MESSAEG_TYPES,
  payload: {},
});

export const onLoginSuccessMessage = (
  id: string,
  pw: string,
  accessToken: string,
  refreshToken: string,
) => ({
  type: ON_LOGIN_SUCCESS_MESSAGE_TYPES,
  payload: {
    id,
    pw,
    accessToken,
    refreshToken,
  },
});

export const onDeviceInfoMessage = (width: number, height: number) => ({
  type: ON_DEVICE_INFO_MESSAGE_TYPES,
  payload: {
    width,
    height,
  },
});

export const onUpdatePasswordMessage = (pw: string) => ({
  type: ON_UPDATE_PASSWORD_TYPES,
  payload: { pw },
});

export const logoutMessage = () => ({
  type: LOGOUT_MESSAGE_TYPES,
  payload: {},
});

export type MessageTypes =
  | ReturnType<typeof autoLoginMessage>
  | ReturnType<typeof onBackPressedMessage>
  | ReturnType<typeof onExitAppMessage>
  | ReturnType<typeof onLoginSuccessMessage>
  | ReturnType<typeof onDeviceInfoMessage>
  | ReturnType<typeof onUpdatePasswordMessage>
  | ReturnType<typeof logoutMessage>;
