import CHeaderContainer from '@components/common/CHeader/containers/CHeaderContainer';
import images from 'assets/images';
import React from 'react';
import SelectItemContainer from './containers/SelectItemContainer';

type Props = {
  room: 'A' | 'B';
  setRoom: React.Dispatch<React.SetStateAction<'A' | 'B'>>;
  minDate: string;
  maxDate: string;
  selectedDate: string;
  getStatus: (time: string) => 'active' | 'default' | 'disabled';
  onTimeClicked: (time: string) => void;
  onDateChanged: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isRegisterReady: boolean;
  refresh: boolean;
  onRegisterClicked: () => void;
};

const Reservation = ({
  room,
  setRoom,
  minDate,
  maxDate,
  selectedDate,
  onTimeClicked,
  getStatus,
  onDateChanged,
  isRegisterReady,
  refresh,
  onRegisterClicked,
}: Props) => {
  const today = new Date();
  const endDate = new Date(
    today.getFullYear(),
    today.getMonth() + 1,
    today.getDate(),
  );
  return (
    <div className='reservation-root'>
      <CHeaderContainer title='예약등록' />
      <div className='content-wrapper'>
        <div className='form-item'>
          <div className='form-item-label'>스터디 룸</div>
          <div className='select-container'>
            <SelectItemContainer
              label='1층 스터디룸 A'
              status={room === 'A' ? 'active' : 'default'}
              onItemClicked={() => setRoom('A')}
            />
            <SelectItemContainer
              label='3층 스터디룸 B'
              status={room === 'B' ? 'active' : 'default'}
              onItemClicked={() => setRoom('B')}
            />
          </div>
        </div>
        <div className='form-item'>
          <div className='form-item-label'>예약 날짜</div>
          <div className='select-container'>
            <div className='input-wrapper'>
              {refresh && (
                <input
                  type={'date'}
                  min={minDate}
                  max={maxDate}
                  value={selectedDate}
                  onChange={onDateChanged}
                />
              )}
              <img src={images.reservation.date} alt='ic-date' />
            </div>
          </div>
        </div>
        <div className='form-item'>
          <div className='form-item-label'>예약 시간</div>
          <div className='select-container'>
            {[
              '09:00',
              '10:00',
              '11:00',
              '12:00',
              '13:00',
              '14:00',
              '15:00',
              '16:00',
              '17:00',
              '18:00',
              '19:00',
              '20:00',
              '21:00',
            ].map((item, index) => {
              return (
                <SelectItemContainer
                  key={index}
                  label={`${item} ~ ${parseInt(item.split(':')[0]) + 1}:00`}
                  status={getStatus(item)}
                  onItemClicked={() => onTimeClicked(item)}
                />
              );
            })}
          </div>
        </div>
        <button
          className='register-button'
          disabled={!isRegisterReady}
          onClick={onRegisterClicked}>
          예약 등록
        </button>
      </div>
    </div>
  );
};

export default Reservation;
