import React, { useEffect, useRef } from 'react';
import CInput from '../CInput';

type Props = {
  onError: {
    error: boolean;
    alertMessage: string;
  };
  placeHolder: string;
  onInputChanged: (val: string) => void;
  type: React.HTMLInputTypeAttribute;
  disabled?: boolean;
};

const CInputContainer = (props: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef?.current) {
      inputRef.current.style.borderColor = props.onError.error
        ? '#DC0000'
        : '#8DBFC6';
    }
  }, [inputRef, props.onError]);

  useEffect(() => {
    if (inputRef?.current) {
      inputRef.current.style.borderColor = props.disabled
        ? '#797979'
        : '#8DBFC6';
    }
  }, [inputRef, props.disabled]);

  return (
    <CInput {...props} inputRef={inputRef} disabled={props.disabled || false} />
  );
};

export default CInputContainer;
