import usePopUp from '@hooks/usePopUp';
import React, { ReactNode, useCallback } from 'react';
import './styles/popUp.styles.css';

type Props = {
  child: ReactNode;
};

const PopUp = ({ child }: Props) => {
  const { __hidePopupFromHooks } = usePopUp();

  return (
    <div
      className='popup-backdrop'
      onClick={(e) => {
        if (e.target === e.currentTarget) {
          __hidePopupFromHooks();
        }
      }}>
      {child}
    </div>
  );
};

export default PopUp;
