import images from 'assets/images';
import React from 'react';
import './styles/cheader.styles.css';

type Props = {
  onBackBtnClicked: () => void;
  title: string;
};

const CHeader = ({ onBackBtnClicked, title }: Props) => {
  return (
    <div className='cheader-root'>
      <div className='back-btn-section' onClick={onBackBtnClicked}>
        <img src={images.common.back_btn} alt='CHeader Back Btn' />
      </div>
      <span className='title'>{title}</span>
    </div>
  );
};

export default CHeader;
