import React from 'react';
import images from 'assets/images';
import CHeaderContainer from '@components/common/CHeader/containers/CHeaderContainer';
import '../styles/policy.styles.css';

type Props = {
  isAgree: boolean;
  onAgreeClicked: () => void;
  onNextClicked: () => void;
  onPolicyClicked: () => void;
};

const Policy = ({
  isAgree,
  onAgreeClicked,
  onNextClicked,
  onPolicyClicked,
}: Props) => {
  return (
    <div className='policy-root'>
      <CHeaderContainer title='개인정보처리방침' />
      <div className='image-wrapper'>
        <img src={images.login.logo_icon} alt='ic-logo' />
        <span className='label-text'>사회과학대학교</span>
        <span className='label-text'>온라인예약시스템</span>
      </div>
      <div className='button-section'>
        <div className='check-item'>
          <div className='check-wrapper' onClick={onAgreeClicked}>
            <img
              src={
                isAgree
                  ? images.reservation.selected
                  : images.reservation.unselected
              }
              alt='ic-check'
            />
            <span>개인정보처리방침 동의</span>
          </div>

          <button onClick={onPolicyClicked}>
            <img src={images.common.right_arrow} alt='ic-right-arrow' />
          </button>
        </div>
        <button
          className='next-step-button'
          disabled={!isAgree}
          onClick={onNextClicked}>
          다음단계
        </button>
      </div>
    </div>
  );
};

export default Policy;
