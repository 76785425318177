import { combineReducers } from 'redux';
import accessTokenReducer from './accessToken/reducers';
import popUpReducer from './popUp/reducers';

const rootReducer = combineReducers({
  popUpReducer,
  accessTokenReducer,
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
