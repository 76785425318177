import React from 'react';
import '../styles/delete.popup.styles.css';

type Props = {
  onCancelClicked: () => void;
  onDeleteClicked: () => void;
};

const DeletePopup = ({ onDeleteClicked, onCancelClicked }: Props) => {
  return (
    <div className='delete-popup-root'>
      <div className='popup-header'>
        <span>회원 탈퇴</span>
      </div>
      <div className='popup-content'>
        <span>
          정말로 탈퇴하시겠습니까?
          <br />
        </span>
        <span>
          계정 정보가 모두 <strong>삭제</strong>됩니다
        </span>
      </div>

      <div className='popup-footer'>
        <button className='cancel-button' onClick={onCancelClicked}>
          취소
        </button>
        <button className='change-button' onClick={onDeleteClicked}>
          확인
        </button>
      </div>
    </div>
  );
};

export default DeletePopup;
