import useAccessToken from '@hooks/useAccessToken';
import usePopUp from '@hooks/usePopUp';
import { apiRoute, requestDelete } from '@libs/api/api';
import { logoutMessage } from '@libs/message/components/messages';
import React, { useCallback } from 'react';
import DeletePopup from '../components/DeletePopup';

const DeletePopupContainer = () => {
  const { accessToken } = useAccessToken();
  const { __hidePopupFromHooks } = usePopUp();
  const onCancelClicked = useCallback(() => {
    __hidePopupFromHooks();
  }, []);

  const onDeleteClicked = useCallback(async () => {
    const { data, config } = await requestDelete<{}>(apiRoute.auth.delete, {
      Authorization: `Bearer ${accessToken}`,
    });
    __hidePopupFromHooks();
    window.ReactNativeWebView.postMessage(JSON.stringify(logoutMessage()));
  }, []);

  return (
    <DeletePopup
      onCancelClicked={onCancelClicked}
      onDeleteClicked={onDeleteClicked}
    />
  );
};

export default DeletePopupContainer;

//
