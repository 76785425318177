import { updateAccessTokenAction } from '@store/accessToken/actions';
import { RootState } from '@store/rootReducer';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export default function useAccessToken() {
  const dispatch = useDispatch();
  const accessToken = useSelector((root: RootState) => root.accessTokenReducer);

  const __updateAccessToken = useCallback(
    (payload: string) => {
      dispatch(updateAccessTokenAction(payload));
    },
    [dispatch],
  );

  useEffect(() => {
    console.log(accessToken);
  }, []);

  return { accessToken, __updateAccessToken };
}
