import React from 'react';
import { RefObject } from 'react';
import '../styles/name.popup.styles.css';

type Props = {
  nameRef: RefObject<HTMLInputElement>;
  onChangeClicked: () => void;
  onCancelClicked: () => void;
};

const NamePopup = ({ nameRef, onChangeClicked, onCancelClicked }: Props) => {
  return (
    <div className='name-popup-root'>
      <div className='popup-header'>
        <span>이름변경</span>
      </div>
      <div className='popup-content'>
        <input
          type='text'
          placeholder='사용하실 이름을 입력해주세요'
          ref={nameRef}
        />
      </div>
      <div className='popup-footer'>
        <button className='cancel-button' onClick={onCancelClicked}>
          취소
        </button>
        <button className='change-button' onClick={onChangeClicked}>
          변경
        </button>
      </div>
    </div>
  );
};

export default NamePopup;
