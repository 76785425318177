import CHeaderContainer from '@components/common/CHeader/containers/CHeaderContainer';
import CInputContainer from '@components/common/CInput/containers/CInputContainer';
import React from 'react';
import './styles/find.password.styles.css';

type Props = {
  setStuId: React.Dispatch<React.SetStateAction<string>>;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  needEmailCheck: boolean;
  setNeedEmailCheck: React.Dispatch<React.SetStateAction<boolean>>;
  doneBtnActive: boolean;
  isPasswordSended: boolean;
  onDoneBtnClicked: () => void;
  isEmailFound: boolean;
};

const FindPassword = ({
  setStuId,
  setEmail,
  setNeedEmailCheck,
  needEmailCheck,
  doneBtnActive,
  isPasswordSended,
  onDoneBtnClicked,
  isEmailFound,
}: Props) => {
  return (
    <div className='findpassword-root'>
      <CHeaderContainer title='비밀번호 찾기' />
      <div className='content-root'>
        <div className='input-item'>
          <span className='input-label'>학번</span>
          <CInputContainer
            onError={{
              error: false,
              alertMessage: 'NO ERROR MESSAGE',
            }}
            onInputChanged={(val) => setStuId(val)}
            placeHolder='학번을 입력해주세요'
            type='number'
          />
        </div>
        <div className='input-item'>
          <span className='input-label'>이메일</span>
          <CInputContainer
            onError={{
              error: needEmailCheck && !isEmailFound,
              alertMessage: '존재하지 않는 이메일 입니다',
            }}
            onInputChanged={(val) => {
              setEmail(val);
              setNeedEmailCheck(true);
            }}
            placeHolder='이메일을 입력해주세요'
            type='email'
          />
        </div>
        {doneBtnActive && !isPasswordSended ? (
          <span className='done-btn active' onClick={onDoneBtnClicked}>
            임시 비밀번호 발급
          </span>
        ) : (
          <span className='done-btn deactive'>임시 비밀번호 발급</span>
        )}
      </div>
    </div>
  );
};

export default FindPassword;
