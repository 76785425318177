import React, { useState } from 'react';
import SelectItem from '../components/SelectItem';

type Props = {
  onItemClicked: () => void;
  label: string;
  status: 'active' | 'default' | 'disabled';
};

const SelectItemContainer = ({ onItemClicked, label, status }: Props) => {
  return (
    <SelectItem label={label} status={status} onItemClicked={onItemClicked} />
  );
};

export default SelectItemContainer;
