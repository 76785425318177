import React, { useCallback, useEffect } from 'react';
import '@styles/core.css';
import RootNavigationContainer from '@routes/containers/RootNavigationContainer';
import { MessageTypes } from '@libs/message/components/messages';
import {
  AUTO_LOGIN_MESSAGE_TYPE,
  ON_DEVICE_INFO_MESSAGE_TYPES,
  ON_LOGIN_SUCCESS_MESSAGE_TYPES,
} from '@libs/message/components/modules/messageTypes';
import { createStore } from 'redux';
import rootReducer from '@store/rootReducer';
import { Provider } from 'react-redux';

declare global {
  interface Window {
    ReactNativeWebView: {
      postMessage: (message: string) => void;
    };
  }
}

const store = createStore(rootReducer);

const App = () => {
  const onMessageHandler = useCallback((event: Event) => {
    const data =
      (event as any)?.data ||
      JSON.stringify({
        type: '@@ERROR_MESSAGE_TYPES',
        payload: {},
      });

    const { type, payload } = JSON.parse(data) as MessageTypes;
    console.log({ type, payload });
    switch (type) {
      case ON_LOGIN_SUCCESS_MESSAGE_TYPES:
        break;
      case ON_DEVICE_INFO_MESSAGE_TYPES:
        document.body.style.width = payload.width + 'px';
        document.body.style.height = payload.height + 'px';
        break;
      default:
        return;
    }

    console.log(type, payload);
  }, []);

  useEffect(() => {
    document.addEventListener('message', onMessageHandler);
    window.addEventListener('message', onMessageHandler);

    return () => {
      document.removeEventListener('message', onMessageHandler);
      window.removeEventListener('message', onMessageHandler);
    };
  }, []);

  return (
    <Provider store={store}>
      <RootNavigationContainer />
    </Provider>
  );
};

export default App;
