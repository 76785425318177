import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import CHeader from '../CHeader';

type Props = {
  title: string;
};

const CHeaderContainer = ({ title }: Props) => {
  const navigate = useNavigate();

  const onBackBtnClicked = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return <CHeader title={title} onBackBtnClicked={onBackBtnClicked} />;
};

export default CHeaderContainer;
