import { alertMessage } from '@libs/alert';
import { apiRoute, requestGet, requestPost } from '@libs/api/api';
import { MessageTypes } from '@libs/message/components/messages';
import { ON_BACK_PRESSED_MESSAGE_TYPES } from '@libs/message/components/modules/messageTypes';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import FindPassword from '../FindPassword';

const FindPasswordContainer = () => {
  const navigate = useNavigate();
  const [stuId, setStuId] = useState('');
  const [email, setEmail] = useState('');
  const [needEmailCheck, setNeedEmailCheck] = useState(false);
  const [isEmailFound, setIsEmailFound] = useState(false);
  const [isPasswordSended, setIsPasswordSended] = useState(false);

  const isStuIdValid = useMemo(() => {
    return stuId !== '';
  }, [stuId]);

  const isKnuEmail = useMemo(() => {
    return email.endsWith('@knu.ac.kr');
  }, [email]);

  const isEmailValid = useMemo(() => {
    return isKnuEmail && needEmailCheck && isEmailFound;
  }, [needEmailCheck, isEmailFound]);

  const doneBtnActive = useMemo(() => {
    return isStuIdValid && isEmailValid;
  }, [isStuIdValid, isEmailValid]);

  const onMessageHandler = useCallback((event: Event) => {
    const data =
      (event as any)?.data ||
      JSON.stringify({
        type: '@@ERROR_MESSAGE_TYPES',
        payload: {},
      });

    const { type, payload } = JSON.parse(data) as MessageTypes;
    switch (type) {
      case ON_BACK_PRESSED_MESSAGE_TYPES:
        onBackBtnClicked();
        return;
    }
  }, []);

  const onBackBtnClicked = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const checkEmailFound = useCallback(async () => {
    const { data } = await requestGet<boolean>(
      apiRoute.user.duplication + '?email=' + email,
      {},
    );

    setIsEmailFound(data);
  }, [email]);

  const onDoneBtnClicked = useCallback(async () => {
    const {
      config: { statusCode },
    } = await requestPost<{}>(
      apiRoute.user.findpassword,
      {},
      {
        email,
        studentId: stuId,
      },
    );

    if (statusCode === 200) {
      alertMessage('임시 비밀번호가 발송 되었습니다');
      setIsPasswordSended(true);
    } else {
      alertMessage('임시 비밀번호 발송에 문제가 있습니다');
    }
  }, [stuId, email]);

  useEffect(() => {
    checkEmailFound();
  }, [checkEmailFound]);

  useEffect(() => {
    document.addEventListener('message', onMessageHandler);

    return () => {
      document.removeEventListener('message', onMessageHandler);
    };
  }, []);

  return (
    <FindPassword
      setStuId={setStuId}
      setEmail={setEmail}
      needEmailCheck={needEmailCheck}
      setNeedEmailCheck={setNeedEmailCheck}
      onDoneBtnClicked={onDoneBtnClicked}
      doneBtnActive={doneBtnActive}
      isPasswordSended={isPasswordSended}
      isEmailFound={isEmailFound}
    />
  );
};

export default FindPasswordContainer;
