const images = {
  common: {
    error: '/assets/common/ic-error.png',
    right_arrow: '/assets/common/ic-right-arrow.png',
    wim_logo: '/assets/common/ic-wim-logo.png',
    back_btn: '/assets/common/ic-back-btn.png',
  },
  login: {
    logo_icon: '/assets/login/ic-logo-colored.png',
    email_input: '/assets/login/ic-email-input.png',
    password_input: '/assets/login/ic-password-input.png',
  },
  home: {
    home_smile: '/assets/home/ic-home-smile.png',
    home_calendar: '/assets/home/ic-home-calendar.png',
    home_setting: '/assets/home/ic-setting.png',
    home_lock: '/assets/home/ic-lock.png',
    home_add: '/assets/home/ic-add@3x.png',
  },
  reservation: {
    unselected: '/assets/reservation/ic-select.png',
    selected: '/assets/reservation/ic-select-active.png',
    date: '/assets/reservation/ic-date.png',
  },
};

export default images;
