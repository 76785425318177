import PopUp from '@components/common/PopUp/PopUp';
import FindPasswordContainer from '@components/FindPassword/containers/FindPasswordContainer';
import HomeContainer from '@components/Home/containers/HomeContainer';
import LoginContainer from '@components/Login/containers/LoginContainer';
import Personal from '@components/Personal/Personal';
import ReservationContainer from '@components/Reservation/containers/ReservationContainer';
import PolicyContainer from '@components/SignUp/containers/PolicyContainer';
import SignUpContainer from '@components/SignUp/containers/SignUpContainer';
import { PopUpTypes } from '@typedef/components/Popup/popup.types';
import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

type Props = {
  popUp: PopUpTypes;
};

const RootNavigation = ({ popUp }: Props) => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/login' element={<LoginContainer />} />
        <Route path='/findpassword' element={<FindPasswordContainer />} />
        <Route path='/policy' element={<PolicyContainer />} />
        <Route path='/policy/personal' element={<Personal />} />
        <Route path='/signup' element={<SignUpContainer />} />
        <Route path='/home' element={<HomeContainer />} />
        <Route path='/reservation' element={<ReservationContainer />} />
        <Route path='*' element={<Navigate to='/login' />} />
      </Routes>
      {popUp.isShown && <PopUp child={popUp.popUp} />}
    </BrowserRouter>
  );
};

export default RootNavigation;
