import React, { useCallback, useEffect, useMemo, useState } from 'react';
import SignUp from '../SignUp';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import { apiRoute, requestGet, requestPost } from '@libs/api/api';
import { alertMessage } from '@libs/alert';
import { MessageTypes } from '@libs/message/components/messages';
import { ON_BACK_PRESSED_MESSAGE_TYPES } from '@libs/message/components/modules/messageTypes';
import usePopUp from '@hooks/usePopUp';
import ExitPopupContainer from '@components/Home/containers/ExitPopupContainer';

const SignUpContainer = () => {
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [stuId, setStuId] = useState('');
  const [needStuIdCheck, setNeedStuIdCheck] = useState(false);
  const [isStuIdUnique, setIsStuIdUnique] = useState(true);
  const [email, setEmail] = useState('');
  const [needEmailCheck, setNeedEmailCheck] = useState(false);
  const [isEmailUnique, setIsEmailUnique] = useState(true);
  const [isVerifyMode, setIsVerifyMode] = useState(false);
  const [verifyCode, setVerifyCode] = useState('');
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [pw, setPw] = useState('');
  const [pwConfirm, setPwConfirm] = useState('');
  const [needPwConfirm, setNeedPwConfirm] = useState(false);

  const isNameValid = useMemo(() => {
    return /^([가-힣a-zA-Z]{2,})$/.test(name);
  }, [name]);

  const isStuIdValid = useMemo(() => {
    return isStuIdUnique;
  }, [stuId, isStuIdUnique]);

  const isKnuEmail = useMemo(() => {
    return email.endsWith('@knu.ac.kr');
  }, [email]);

  const isEmailReadyToVerify = useMemo(() => {
    return needEmailCheck && isEmailUnique && isKnuEmail;
  }, [needEmailCheck, isEmailUnique, isKnuEmail]);

  const isEmailValid = useMemo(() => {
    return isKnuEmail && isEmailVerified;
  }, [isKnuEmail, isEmailVerified]);

  const passwordConfirmed = useMemo(() => {
    return needPwConfirm && pw === pwConfirm;
  }, [needPwConfirm, pw, pwConfirm]);

  const isPasswordValid = useMemo(() => {
    return passwordConfirmed;
  }, [passwordConfirmed]);

  const doneBtnActive = useMemo(() => {
    return isNameValid && isStuIdValid && isEmailValid && isPasswordValid;
  }, [isNameValid, isStuIdValid, isEmailValid, isPasswordValid]);

  const onMessageHandler = useCallback((event: Event) => {
    const data =
      (event as any)?.data ||
      JSON.stringify({
        type: '@@ERROR_MESSAGE_TYPES',
        payload: {},
      });

    const { type, payload } = JSON.parse(data) as MessageTypes;
    switch (type) {
      case ON_BACK_PRESSED_MESSAGE_TYPES:
        onBackBtnClicked();
        return;
    }
  }, []);

  const onBackBtnClicked = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const checkStuIdDuplicate = useCallback(async () => {
    const { data } = await requestGet<boolean>(
      apiRoute.user.duplication + '?studentId=' + stuId,
      {},
    );

    setIsStuIdUnique(!data);
  }, [stuId]);

  const checkEmailDuplicate = useCallback(() => {
    _.debounce(async () => {
      const { data } = await requestGet<boolean>(
        apiRoute.user.duplication + '?email=' + email,
        {},
      );

      setIsEmailUnique(!data);
    }, 500);
  }, [email]);

  const onEmailConfirmNeeded = useCallback(() => {
    setNeedEmailCheck(true);
  }, []);

  const onEmailVerifyClicked = useCallback(async () => {
    requestPost(apiRoute.auth.email + '?email=' + email, {}, {});

    setIsVerifyMode(true);
  }, [email]);

  const onVerifyCodeConfirmClicked = useCallback(async () => {
    const response = await requestGet<{}>(
      apiRoute.auth.verify + `/${email}?verifyNumber=${verifyCode}`,
      {},
    );

    console.log(response);

    switch (response.config.statusCode) {
      case 200:
        setIsEmailVerified(true);
        setIsVerifyMode(false);
        alertMessage('이메일이 인증되었습니다');
        break;
      default:
        setIsEmailVerified(false);
        setIsVerifyMode(true);
        alertMessage('인증 코드가 다릅니다');
    }
  }, [email, verifyCode]);

  const onPwConfirmNeeded = useCallback(() => {
    setNeedPwConfirm(true);
  }, []);

  const onDoneBtnClicked = useCallback(async () => {
    const {
      config: { statusCode },
    } = await requestPost<{}>(
      apiRoute.user.signup,
      {},
      {
        email,
        password: pw,
        name,
        studentId: stuId,
      },
    );

    if (statusCode === 201) {
      alertMessage('회원가입이 완료되었습니다');
      navigate(-1);
    } else {
      alertMessage('회원가입에 실해하었습니다');
    }
  }, [navigate, email, pw, name, stuId]);

  useEffect(() => {
    checkStuIdDuplicate();
  }, [checkStuIdDuplicate]);

  useEffect(() => {
    checkEmailDuplicate();
  }, [checkEmailDuplicate]);

  useEffect(() => {
    setIsVerifyMode(false);
  }, [email]);

  useEffect(() => {
    document.addEventListener('message', onMessageHandler);

    return () => {
      document.removeEventListener('message', onMessageHandler);
    };
  }, []);

  return (
    <SignUp
      doneBtnActive={doneBtnActive}
      setName={setName}
      setStuId={setStuId}
      needStuIdCheck={needStuIdCheck}
      setNeedStuIdCheck={setNeedStuIdCheck}
      isStuIdUnique={isStuIdUnique}
      email={email}
      setEmail={setEmail}
      needEmailCheck={needEmailCheck}
      onEmailConfirmNeeded={onEmailConfirmNeeded}
      isEmailUnique={isEmailUnique}
      isKnuEmail={isKnuEmail}
      isEmailReadyToVerify={isEmailReadyToVerify}
      onEmailVerifyClicked={onEmailVerifyClicked}
      isVerifyMode={isVerifyMode}
      setVerifyCode={setVerifyCode}
      onVerifyCodeConfirmClicked={onVerifyCodeConfirmClicked}
      isEmailVerified={isEmailVerified}
      setPw={setPw}
      needPwConfirm={needPwConfirm}
      setPwConfirm={setPwConfirm}
      onPwConfirmNeeded={onPwConfirmNeeded}
      passwordConfirmed={passwordConfirmed}
      onDoneBtnClicked={onDoneBtnClicked}
    />
  );
};

export default SignUpContainer;
