import CInputContainer from '@components/common/CInput/containers/CInputContainer';
import { clockToTime, timeToClock } from '@libs/factory/factory';
import { ReservationTypes } from '@typedef/components/Home/home.types';
import images from 'assets/images';
import React from 'react';
import './styles/home.styles.css';

type Props = {
  reservationList: ReservationTypes[];
  name: string;
  setName: React.Dispatch<React.SetStateAction<string>>;
  studentId: string;
  setStudentId: React.Dispatch<React.SetStateAction<string>>;
  onChangeNameClicked: () => void;
  onChangeStudentIdClicked: () => void;
  onChangePasswordClicked: () => void;
  onLogoutClicked: () => void;
  onDeleteClicked: () => void;
  onReservationClicked: () => void;
  onReservationDeleted: () => void;
};

const Home = ({
  reservationList,
  name,
  setName,
  studentId,
  setStudentId,
  onChangeNameClicked,
  onChangeStudentIdClicked,
  onChangePasswordClicked,
  onLogoutClicked,
  onDeleteClicked,
  onReservationClicked,
  onReservationDeleted,
}: Props) => {
  return (
    <div className='home-root'>
      <div className='home-header'>
        <span>사회과학대학교 온라인 예약 시스템</span>
      </div>
      <div className='resv-root'>
        <span className='user-name'>
          <span>{name}</span>
          <img src={images.home.home_smile} alt='Home Smile Icon' />
        </span>
        <div className='title-section'>
          <span className='resv-title'>
            <img src={images.home.home_calendar} alt='Home Calendar' />
            <span>나의 예약 내역</span>
          </span>
          <button className='delete-button' onClick={onReservationDeleted}>
            예약삭제
          </button>
        </div>

        <div className='resv-list'>
          {reservationList.length === 0 ? (
            <span className='resv-no-list'>{`예약 내역이 없습니다.
터치하여 새로운 예약 일정을 만들어 주세요`}</span>
          ) : (
            reservationList.map((item, index) => {
              const startTime = timeToClock(item.time.start);
              const endTime = timeToClock(item.time.end);
              return (
                <span className='resv-list-item' key={index}>
                  <span>{item.date}</span>
                  <span>
                    {startTime < 9
                      ? clockToTime(startTime + 12)
                      : item.time.start}
                  </span>
                  <span>~</span>
                  <span>
                    {endTime < 9 ? clockToTime(endTime + 12) : item.time.end}
                  </span>
                  <span className='item-room'> {item.room}</span>
                </span>
              );
            })
          )}
        </div>
        <button className='add-resv-btn' onClick={onReservationClicked}>
          <span>새 예약 등록하기</span>
          <img src={images.home.home_add} alt='Add reservation button' />
        </button>
        <div className='background'></div>
      </div>
      <div className='setting-root'>
        <div className='setting-title-wrapper'>
          <img src={images.home.home_setting} alt='Home User Setting' />
          <span className='title'>설정</span>
        </div>
        <div className='setting-items-wrapper'>
          <div className='setting-item'>
            <div className='item-title'>
              <span>이름</span>
            </div>
            <div className='item-value'>
              <span>{name}</span>
              <span className='change-item-btn' onClick={onChangeNameClicked}>
                변경하기
              </span>
            </div>
          </div>
          <div className='setting-item'>
            <div className='item-title'>
              <span>학번</span>
            </div>
            <div className='item-value'>
              <span>{studentId}</span>
              <span
                className='change-item-btn'
                onClick={onChangeStudentIdClicked}>
                변경하기
              </span>
            </div>
          </div>
          <div
            className='setting-item password'
            onClick={onChangePasswordClicked}>
            <img src={images.home.home_lock} alt='chagne user password' />
            <span>비밀번호 변경</span>
          </div>
        </div>
        <div className='setting-btns-wrapper'>
          <button className='delete-account-btn' onClick={onDeleteClicked}>
            회원탈퇴
          </button>
          <button className='logout-btn' onClick={onLogoutClicked}>
            로그아웃
          </button>
        </div>
      </div>
    </div>
  );
};

export default Home;
