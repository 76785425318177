import useAccessToken from '@hooks/useAccessToken';
import usePopUp from '@hooks/usePopUp';
import { apiRoute, requestGet, requestPatch } from '@libs/api/api';
import { UserInfoTypes } from '@typedef/components/Home/home.types';
import React, { useCallback, useRef } from 'react';
import { useState } from 'react';
import { useMemo } from 'react';
import { RefObject } from 'react';
import StudentIdPopup from '../components/StudentIdPopup';

type Props = {
  setStudentId: React.Dispatch<React.SetStateAction<string>>;
};

const StudentIdPopupContainer = ({ setStudentId }: Props) => {
  const { accessToken } = useAccessToken();
  const { __hidePopupFromHooks } = usePopUp();
  const studentIdRef = useRef() as RefObject<HTMLInputElement>;
  const [isUnique, setIsUnique] = useState(true);

  const onCancelClicked = useCallback(() => {
    __hidePopupFromHooks();
  }, []);

  const updateStudentId = useCallback(
    async (studentId: string) => {
      const {
        config: { statusCode },
      } = await requestPatch<UserInfoTypes>(
        apiRoute.user.update,
        {
          Authorization: `Bearer ${accessToken}`,
        },
        { studentId: studentId },
      );

      if (statusCode === 200) {
        setStudentId(studentId);
        __hidePopupFromHooks();
      }
    },
    [accessToken],
  );

  const onChangeClicked = useCallback(async () => {
    if (studentIdRef.current) {
      const studentId = studentIdRef.current.value;

      const {
        data,
        config: { statusCode },
      } = await requestGet<boolean>(
        apiRoute.user.duplication + `?studentId=${studentId}`,
        {},
      );

      setIsUnique(!data);
      if (!data) {
        console.log('it is unique');
        updateStudentId(studentId);
      }
    }
  }, [accessToken]);

  return (
    <StudentIdPopup
      isUnique={isUnique}
      studentIdRef={studentIdRef}
      onCancelClicked={onCancelClicked}
      onChangeClicked={onChangeClicked}
    />
  );
};

export default StudentIdPopupContainer;
