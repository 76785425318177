import useAccessToken from '@hooks/useAccessToken';
import usePopUp from '@hooks/usePopUp';
import { apiRoute, requestPatch } from '@libs/api/api';
import { onUpdatePasswordMessage } from '@libs/message/components/messages';
import { UserInfoTypes } from '@typedef/components/Home/home.types';
import React, { RefObject, useCallback, useRef } from 'react';
import { useState } from 'react';
import { useMemo } from 'react';
import PasswordPopup from '../components/PasswordPopup';

const PasswordPopupContainer = () => {
  const { accessToken } = useAccessToken();
  const { __hidePopupFromHooks } = usePopUp();
  const [password, setPassword] = useState('');
  const [passwordCheck, setPasswordCheck] = useState('');

  const isChecked = useMemo(() => {
    return password === passwordCheck ? true : false;
  }, [password, passwordCheck]);

  const onCancelClicked = useCallback(() => {
    __hidePopupFromHooks();
  }, []);

  const onChangeClicked = useCallback(async () => {
    if (password === passwordCheck) {
      const {
        config: { statusCode },
      } = await requestPatch<UserInfoTypes>(
        apiRoute.user.update,
        {
          Authorization: `Bearer ${accessToken}`,
        },
        { password: password },
      );

      if (statusCode === 200) {
        window.ReactNativeWebView.postMessage(
          JSON.stringify(onUpdatePasswordMessage(password)),
        );
        __hidePopupFromHooks();
      }
    }
  }, [password, passwordCheck, accessToken]);
  return (
    <PasswordPopup
      isChecked={isChecked}
      setPassword={setPassword}
      setPasswordCheck={setPasswordCheck}
      onCancelClicked={onCancelClicked}
      onChangeClicked={onChangeClicked}
    />
  );
};

export default PasswordPopupContainer;
