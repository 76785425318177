import images from 'assets/images';
import React from 'react';

type Props = {
  status: 'active' | 'default' | 'disabled';
  label: string;
  onItemClicked: () => void;
};

const SelectItem = ({ label, status, onItemClicked }: Props) => {
  return (
    <div
      className={`select-box ${status === 'disabled' && 'disabled'}`}
      onClick={onItemClicked}>
      <div className='label'>{label}</div>
      <img
        src={
          status === 'active'
            ? images.reservation.selected
            : images.reservation.unselected
        }
        alt='ic-select'
      />
    </div>
  );
};

export default SelectItem;
