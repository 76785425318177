import React, { RefObject } from 'react';
import '../styles/student.id.popup.styles.css';

type Props = {
  isUnique: boolean;
  studentIdRef: RefObject<HTMLInputElement>;
  onChangeClicked: () => void;
  onCancelClicked: () => void;
};

const StudentIdPopup = ({
  isUnique,
  studentIdRef,
  onChangeClicked,
  onCancelClicked,
}: Props) => {
  return (
    <div className='id-popup-root'>
      <div className='popup-header'>
        <span>학번 변경</span>
      </div>
      <div className='popup-content'>
        <input
          type='text'
          placeholder='학번을 입력해주세요'
          ref={studentIdRef}
          className={isUnique ? '' : 'error'}
        />
        {!isUnique && (
          <span className='error-msg'>이미 등록되어 있는 학번입니다</span>
        )}
      </div>

      <div className='popup-footer'>
        <button className='cancel-button' onClick={onCancelClicked}>
          취소
        </button>
        <button className='change-button' onClick={onChangeClicked}>
          변경
        </button>
      </div>
    </div>
  );
};

export default StudentIdPopup;
