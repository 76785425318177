import useAccessToken from '@hooks/useAccessToken';
import usePopUp from '@hooks/usePopUp';
import { apiRoute, requestPatch } from '@libs/api/api';
import { UserInfoTypes } from '@typedef/components/Home/home.types';
import React from 'react';
import { useCallback } from 'react';
import { RefObject } from 'react';
import { useRef } from 'react';
import NamePopup from '../components/NamePopup';

type Props = {
  setName: React.Dispatch<React.SetStateAction<string>>;
};

const NamePopupContainer = ({ setName }: Props) => {
  const { accessToken } = useAccessToken();
  const { __hidePopupFromHooks } = usePopUp();
  const nameRef = useRef() as RefObject<HTMLInputElement>;

  const onCancelClicked = useCallback(() => {
    __hidePopupFromHooks();
  }, []);

  const onChangeClicked = useCallback(async () => {
    if (nameRef.current) {
      const {
        data: { name },
        config: { statusCode },
      } = await requestPatch<UserInfoTypes>(
        apiRoute.user.update,
        {
          Authorization: `Bearer ${accessToken}`,
        },
        { name: nameRef.current.value },
      );

      if (statusCode === 200) {
        console.log(name, statusCode);
        setName(nameRef.current.value);
        __hidePopupFromHooks();
      }
    }
  }, [accessToken]);

  return (
    <NamePopup
      nameRef={nameRef}
      onCancelClicked={onCancelClicked}
      onChangeClicked={onChangeClicked}
    />
  );
};

export default NamePopupContainer;
