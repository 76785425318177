import React from 'react';
import '../styles/logout.popup.styles.css';

type Props = {
  onCancelClicked: () => void;
  onLogoutClicked: () => void;
};

const LogoutPopup = ({ onLogoutClicked, onCancelClicked }: Props) => {
  return (
    <div className='logout-popup-root'>
      <div className='popup-header'>
        <span>로그아웃</span>
      </div>
      <div className='popup-content'>
        <span>로그아웃 하시겠습니까?</span>
      </div>

      <div className='popup-footer'>
        <button className='cancel-button' onClick={onCancelClicked}>
          취소
        </button>
        <button className='change-button' onClick={onLogoutClicked}>
          확인
        </button>
      </div>
    </div>
  );
};

export default LogoutPopup;
