import useAccessToken from '@hooks/useAccessToken';
import usePopUp from '@hooks/usePopUp';
import { apiRoute, requestGet, requestPost } from '@libs/api/api';
import { clockToTime, timeToClock, twoDigits } from '@libs/factory/factory';
import { MessageTypes } from '@libs/message/components/messages';
import { ON_BACK_PRESSED_MESSAGE_TYPES } from '@libs/message/components/modules/messageTypes';
import { GetHistoryItemType } from '@typedef/components/Reservation/reservation.types';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import Reservation from '../Reservation';
import '../styles/reservation.style.css';
import DateAlertPopupContainer from './DateAlertPopupContainer';
import RegisterPopupContainer from './RegisterPopupContainer';

const today = new Date();
const endDate = new Date(
  today.getFullYear(),
  today.getMonth(),
  today.getDate() + 14,
);

const ReservationContainer = () => {
  const { __showPopUpFromHooks, __hidePopupFromHooks } = usePopUp();
  const { accessToken } = useAccessToken();
  const navigate = useNavigate();

  const minDate = useRef(
    today.getFullYear() +
      '-' +
      twoDigits(today.getMonth() + 1) +
      '-' +
      twoDigits(today.getDate()),
  );
  const maxDate = useRef(
    endDate.getFullYear() +
      '-' +
      twoDigits(endDate.getMonth() + 1) +
      '-' +
      twoDigits(endDate.getDate()),
  );

  const [refresh, setRefresh] = useState(true);
  const [room, setRoom] = useState<'A' | 'B'>('A');
  const [selectedDate, setSelectedDate] = useState(minDate.current);
  const [reservedTime, setReservedTime] = useState<string[]>([]);
  const [selectedTime, setSelectedTime] = useState<string[]>([]);
  const [disabledTime, setDisabledTime] = useState<string[]>([]);

  const isRegisterReady = useMemo(() => {
    return selectedTime.length !== 0;
  }, [selectedTime]);

  const registerReservation = useCallback(async () => {
    const sortedTime = [...selectedTime];
    sortedTime.sort();
    const startDate = new Date(selectedDate);
    const endDate = new Date(selectedDate);
    console.log(parseInt(sortedTime[0].split(':')[0]));
    startDate.setHours(parseInt(sortedTime[0].split(':')[0]) + 9);
    endDate.setHours(
      parseInt(sortedTime[sortedTime.length - 1].split(':')[0]) + 10,
    );

    console.log(startDate);

    const requestBody = {
      room: room === 'A' ? '1층 스터디룸 A' : '3층 스터디룸 B',
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
    };
    console.log(requestBody);
    const {
      data,
      config: { statusCode, message },
    } = await requestPost<void>(
      apiRoute.reservation.register,
      {
        Authorization: `Bearer ${accessToken}`,
      },
      requestBody,
    );

    if (statusCode === -1) {
      if (message === '이미 신청한 사람이 있습니다') {
        return 3;
      }
      return 2;
    }
    console.log('register success');
    getRegisterHistory();
    setSelectedTime([]);
    return 1;
  }, [accessToken, selectedDate, selectedTime, room]);

  const getRegisterHistory = useCallback(async () => {
    const temp: string[] = [];
    console.log(
      apiRoute.reservation.getHistory + `?date=${selectedDate}&room=${room}`,
      {
        Authorization: `Bearer ${accessToken}`,
      },
    );
    const {
      data,
      config: { statusCode },
    } = await requestGet<GetHistoryItemType[]>(
      apiRoute.reservation.getHistory +
        `?date=${selectedDate}&room=${
          room === 'A' ? '1층 스터디룸 A' : '3층 스터디룸 B'
        }`,
      {
        Authorization: `Bearer ${accessToken}`,
      },
    );

    if (statusCode !== 200) {
      console.error('getRegisterHistory error');
      return;
    }
    console.log(data);
    data.forEach((item) => {
      let startTime = timeToClock(item.time.start);
      let endTime = timeToClock(item.time.end);

      for (let i = startTime; i < endTime; i++) {
        temp.push(clockToTime(i));
      }
    });
    setReservedTime(temp);
    setSelectedTime([]);
    setDisabledTime([]);
  }, [accessToken, room, selectedDate]);

  const onRegisterPopupConfirmClicked = useCallback(async () => {
    const result = await registerReservation();
    if (result !== 3) {
      __hidePopupFromHooks();
    } else {
      __hidePopupFromHooks();
      getRegisterHistory();
      setSelectedTime([]);
      __showPopUpFromHooks(
        <DateAlertPopupContainer
          title='예약실패'
          content='이미 예약된 시간입니다'
          onConfirmClicked={onDatePopupConfirmClicked}
        />,
      );
    }
  }, [
    registerReservation,
    __hidePopupFromHooks,
    getRegisterHistory,
    selectedTime,
    __showPopUpFromHooks,
  ]);

  const onRegisterClicked = useCallback(() => {
    __showPopUpFromHooks(
      <RegisterPopupContainer
        onConfirmClicked={onRegisterPopupConfirmClicked}
      />,
    );
  }, [__showPopUpFromHooks, onRegisterPopupConfirmClicked]);

  const onTimeClicked = useCallback(
    (time: string) => {
      // 아이템 클릭시 클릭 아이템 active
      if (disabledTime.includes(time)) {
        return;
      } else if (selectedTime.includes(time)) {
        setSelectedTime((times) => times.filter((item) => item !== time));
      } else {
        setSelectedTime((times) => [...times, time]);
      }
    },
    [selectedTime, disabledTime],
  );

  const onDatePopupConfirmClicked = useCallback(() => {
    __hidePopupFromHooks();
    setRefresh((b) => !b);
  }, [__hidePopupFromHooks, refresh]);

  const onDateChanged = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      // ios validation check
      const date = new Date(e.target.value);
      if (date < today || endDate < date) {
        setRefresh((b) => !b);
        __showPopUpFromHooks(
          <DateAlertPopupContainer
            title='날짜 제한'
            content='예약은 최대 2주 뒤까지 가능합니다'
            onConfirmClicked={onDatePopupConfirmClicked}
          />,
        );
        setSelectedDate(minDate.current);
      } else {
        setSelectedDate(e.target.value);
      }
    },
    [selectedDate, refresh],
  );

  const getStatus = useCallback(
    (time: string): 'active' | 'default' | 'disabled' => {
      if (selectedTime.includes(time)) {
        return 'active';
      }
      if (disabledTime.includes(time)) {
        return 'disabled';
      }
      return 'default';
    },
    [selectedTime, disabledTime],
  );

  const onBackBtnClicked = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const onMessageHandler = useCallback((event: Event) => {
    const data =
      (event as any)?.data ||
      JSON.stringify({
        type: '@@ERROR_MESSAGE_TYPES',
        payload: {},
      });

    const { type, payload } = JSON.parse(data) as MessageTypes;
    switch (type) {
      case ON_BACK_PRESSED_MESSAGE_TYPES:
        onBackBtnClicked();
        return;
    }
  }, []);

  useEffect(() => {
    document.addEventListener('message', onMessageHandler);
    window.addEventListener('message', onMessageHandler);

    return () => {
      document.removeEventListener('message', onMessageHandler);
      window.removeEventListener('message', onMessageHandler);
    };
  }, []);

  useEffect(() => {
    getRegisterHistory();
  }, [selectedDate, room]);

  useEffect(() => {
    if (selectedTime.length === 0) {
      setDisabledTime([...reservedTime]);
    } else if (selectedTime.length === 1) {
      const clock = timeToClock(selectedTime[0]);
      const temp = [...reservedTime];
      for (let i = 9; i < 22; i++) {
        if (i < clock - 2 || clock + 2 < i) {
          temp.push(clockToTime(i));
        }
      }
      setDisabledTime(temp);
    } else if (selectedTime.length === 2) {
      const clock1 = timeToClock(selectedTime[0]);
      const clock2 = timeToClock(selectedTime[1]);
      const min = Math.min(clock1, clock2);
      const max = Math.max(clock1, clock2);
      const temp = [...reservedTime];
      if (max - min === 1) {
        for (let i = 9; i < 22; i++) {
          if (i < min - 1 || max + 1 < i) {
            temp.push(clockToTime(i));
          }
        }
      } else {
        setSelectedTime((prev) => [...prev, clockToTime(min + 1)]);
      }
      setDisabledTime(temp);
    } else {
      const temp = [...reservedTime];
      for (let i = 9; i < 22; i++) {
        const time = clockToTime(i);
        if (!selectedTime.includes(time)) {
          temp.push(time);
        }
      }
      setDisabledTime(temp);
    }
  }, [reservedTime, selectedTime]);

  return (
    <Reservation
      room={room}
      setRoom={setRoom}
      minDate={minDate.current}
      maxDate={maxDate.current}
      selectedDate={selectedDate}
      onTimeClicked={onTimeClicked}
      getStatus={getStatus}
      onDateChanged={onDateChanged}
      isRegisterReady={isRegisterReady}
      refresh={refresh}
      onRegisterClicked={onRegisterClicked}
    />
  );
};

export default ReservationContainer;
