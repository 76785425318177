import usePopUp from '@hooks/usePopUp';
import { onExitAppMessage } from '@libs/message/components/messages';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import ExitPopup from '../components/ExitPopup';

const ExitPopupContainer = () => {
  const navigate = useNavigate();
  const { __hidePopupFromHooks } = usePopUp();
  const onCancelClicked = useCallback(() => {
    __hidePopupFromHooks();
  }, []);

  const onExitClicked = useCallback(() => {
    __hidePopupFromHooks();
    window.ReactNativeWebView.postMessage(JSON.stringify(onExitAppMessage()));
  }, []);

  return (
    <ExitPopup
      onCancelClicked={onCancelClicked}
      onExitClicked={onExitClicked}
    />
  );
};

export default ExitPopupContainer;
