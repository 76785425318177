export const AUTO_LOGIN_MESSAGE_TYPE = 'AUTO_LOGIN_MESSAGE_TYPE' as const;

export const ON_BACK_PRESSED_MESSAGE_TYPES =
  'ON_BACK_PRESSED_MESSAGE_TYPES' as const;

export const ON_EXIT_APP_MESSAEG_TYPES = 'ON_EXIT_APP_MESSAEG_TYPES' as const;

export const ON_LOGIN_SUCCESS_MESSAGE_TYPES =
  'ON_LOGIN_SUCCESS_MESSAGE_TYPES' as const;

export const ON_DEVICE_INFO_MESSAGE_TYPES =
  'ON_DEVICE_INFO_MESSAGE_TYPES' as const;

export const ON_UPDATE_PASSWORD_TYPES = 'ON_UPDATE_PASSWORD_TYPES' as const;

export const LOGOUT_MESSAGE_TYPES = 'LOGOUT_MESSAGE_TYPES' as const;
