import ExitPopupContainer from '@components/Home/containers/ExitPopupContainer';
import usePopUp from '@hooks/usePopUp';
import { apiRoute, requestPost } from '@libs/api/api';
import {
  MessageTypes,
  onLoginSuccessMessage,
} from '@libs/message/components/messages';
import {
  AUTO_LOGIN_MESSAGE_TYPE,
  ON_BACK_PRESSED_MESSAGE_TYPES,
} from '@libs/message/components/modules/messageTypes';
import { LoginResultTypes } from '@typedef/components/Login/login.types';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import Login from '../Login';

const LoginContainer = () => {
  const navigate = useNavigate();

  const emailInputRef = useRef<HTMLInputElement>(null);
  const pwInputRef = useRef<HTMLInputElement>(null);
  const { __showPopUpFromHooks } = usePopUp();
  const [email, setEmail] = useState('');
  const [needEmailCheck, setNeedEmailCheck] = useState(false);
  const [password, setPassword] = useState('');
  const [loginFailed, setLoginFailed] = useState(false);

  const isKnuEmail = useMemo(() => {
    return email.endsWith('@knu.ac.kr');
  }, [email]);

  const onMessageHandler = useCallback((event: Event) => {
    const data =
      (event as any)?.data ||
      JSON.stringify({
        type: '@@ERROR_MESSAGE_TYPES',
        payload: {},
      });

    const { type, payload } = JSON.parse(data) as MessageTypes;
    switch (type) {
      case ON_BACK_PRESSED_MESSAGE_TYPES:
        onBackBtnClicked();
        return;
    }
  }, []);

  const onBackBtnClicked = useCallback(() => {
    __showPopUpFromHooks(<ExitPopupContainer />);
  }, [navigate]);

  const onLoginBtnClicked = useCallback(async () => {
    const {
      data: { accessToken, refreshToken },
      config: { statusCode },
    } = await requestPost<LoginResultTypes>(
      apiRoute.auth.login,
      {},
      { email, password },
    );

    console.log(accessToken, refreshToken, statusCode);

    if (statusCode === -1) {
      console.log('login failed');
      setLoginFailed(true);
    }

    if (accessToken && refreshToken) {
      console.log('login web');
      setLoginFailed(false);
      window.ReactNativeWebView.postMessage(
        JSON.stringify(
          onLoginSuccessMessage(email, password, accessToken, refreshToken),
        ),
      );
    } else {
      console.log('login failed');
      setLoginFailed(true);
    }
  }, [email, password, loginFailed]);

  const onSignUpClicked = useCallback(() => {
    navigate('/policy');
  }, [navigate]);

  const onFindPasswordClicked = useCallback(() => {
    navigate('/findpassword');
  }, [navigate]);

  useEffect(() => {
    if (emailInputRef?.current) {
      emailInputRef.current.style.borderColor =
        needEmailCheck && !isKnuEmail ? '#dc0000' : '#191919';
    }
  }, [emailInputRef, needEmailCheck, isKnuEmail]);

  useEffect(() => {
    if (pwInputRef?.current) {
      pwInputRef.current.style.borderColor = loginFailed
        ? '#dc0000'
        : '#191919';
    }
  }, [pwInputRef, loginFailed]);

  useEffect(() => {
    setLoginFailed(false);
  }, [email, password]);

  useEffect(() => {
    document.addEventListener('message', onMessageHandler);

    return () => {
      document.removeEventListener('message', onMessageHandler);
    };
  }, []);

  return (
    <Login
      emailInputRef={emailInputRef}
      pwInputRef={pwInputRef}
      setNeedEmailCheck={setNeedEmailCheck}
      setEmail={setEmail}
      needEmailCheck={needEmailCheck}
      isKnuEmail={isKnuEmail}
      loginFailed={loginFailed}
      setPassword={setPassword}
      onLoginBtnClicked={onLoginBtnClicked}
      onSignUpClicked={onSignUpClicked}
      onFindPasswordClicked={onFindPasswordClicked}
    />
  );
};

export default LoginContainer;
