import { AccessTokenAction } from './actions';
import { UPDATE_ACCESS_TOKEN } from './modules/actionsTypes';

export type AccessTokenState = string;

const initialState = '';

const accessTokenReducer = (
  state: AccessTokenState = initialState,
  { type, payload }: AccessTokenAction,
): AccessTokenState => {
  switch (type) {
    case UPDATE_ACCESS_TOKEN:
      return payload;
    default:
      return state;
  }
};

export default accessTokenReducer;
