import CHeaderContainer from '@components/common/CHeader/containers/CHeaderContainer';
import React from 'react';
import './style/personal.style.css';

const Personal = () => {
  return (
    <div className='personal-root'>
      <CHeaderContainer title='개인정보처리방침' />
      <p className='p1'>
        <span className='s1'>&lt;</span>
        <span className='s2'>쏙터디룸</span>
        <span className='s1'>(</span>
        <span className='s2'>경북대학교</span>
        <span className='s1'> </span>
        <span className='s2'>사회과학대학</span>
        <span className='s1'> </span>
        <span className='s2'>스터디룸</span>
        <span className='s1'>) </span>
        <span className='s2'>개인정보</span>
        <span className='s1'> </span>
        <span className='s2'>처리방침</span>
        <span className='s1'>
          &gt;<span className='Apple-converted-space'> </span>
        </span>
      </p>
      <p className='p2'>
        <span className='s2'></span>
        <br />
      </p>
      <p className='p1'>
        <span className='s2'>사회과학대학</span>
        <span className='s1'> </span>
        <span className='s2'>학생회는</span>
        <span className='s1'> </span>
        <span className='s2'>정보주체의</span>
        <span className='s1'> </span>
        <span className='s2'>자유와</span>
        <span className='s1'> </span>
        <span className='s2'>권리</span>
        <span className='s1'> </span>
        <span className='s2'>보호를</span>
        <span className='s1'> </span>
        <span className='s2'>위해</span>
        <span className='s1'> </span>
        <span className='s2'>「개인정보</span>
        <span className='s1'> </span>
        <span className='s2'>보호법」</span>
        <span className='s1'> </span>
        <span className='s2'>및</span>
        <span className='s1'> </span>
        <span className='s2'>관계</span>
        <span className='s1'> </span>
        <span className='s2'>법령이</span>
        <span className='s1'> </span>
        <span className='s2'>정한</span>
        <span className='s1'> </span>
        <span className='s2'>바를</span>
        <span className='s1'> </span>
        <span className='s2'>준수하여</span>
        <span className='s1'>, </span>
        <span className='s2'>적법하게</span>
        <span className='s1'> </span>
        <span className='s2'>개인정보를</span>
        <span className='s1'> </span>
        <span className='s2'>처리하고</span>
        <span className='s1'> </span>
        <span className='s2'>안전하게</span>
        <span className='s1'> </span>
        <span className='s2'>관리하고</span>
        <span className='s1'> </span>
        <span className='s2'>있습니다</span>
        <span className='s1'>. </span>
        <span className='s2'>이에</span>
        <span className='s1'> </span>
        <span className='s2'>「개인정보</span>
        <span className='s1'> </span>
        <span className='s2'>보호법」</span>
        <span className='s1'> </span>
        <span className='s2'>제</span>
        <span className='s1'>30</span>
        <span className='s2'>조에</span>
        <span className='s1'> </span>
        <span className='s2'>따라</span>
        <span className='s1'> </span>
        <span className='s2'>정보주체에게</span>
        <span className='s1'> </span>
        <span className='s2'>개인정보</span>
        <span className='s1'> </span>
        <span className='s2'>처리에</span>
        <span className='s1'> </span>
        <span className='s2'>관한</span>
        <span className='s1'> </span>
        <span className='s2'>절차</span>
        <span className='s1'> </span>
        <span className='s2'>및</span>
        <span className='s1'> </span>
        <span className='s2'>기준을</span>
        <span className='s1'> </span>
        <span className='s2'>안내하고</span>
        <span className='s1'>, </span>
        <span className='s2'>이와</span>
        <span className='s1'> </span>
        <span className='s2'>관련</span>
        <span className='s1'> </span>
        <span className='s2'>한</span>
        <span className='s1'> </span>
        <span className='s2'>고충을</span>
        <span className='s1'> </span>
        <span className='s2'>신속하고</span>
        <span className='s1'> </span>
        <span className='s2'>원활하게</span>
        <span className='s1'> </span>
        <span className='s2'>처리할</span>
        <span className='s1'> </span>
        <span className='s2'>수</span>
        <span className='s1'> </span>
        <span className='s2'>있도록</span>
        <span className='s1'> </span>
        <span className='s2'>하기</span>
        <span className='s1'> </span>
        <span className='s2'>위하여</span>
        <span className='s1'> </span>
        <span className='s2'>다음과</span>
        <span className='s1'> </span>
        <span className='s2'>같이</span>
        <span className='s1'> </span>
        <span className='s2'>개인정보</span>
        <span className='s1'> </span>
        <span className='s2'>처리방침을</span>
        <span className='s1'> </span>
        <span className='s2'>수립</span>
        <span className='s1'>·</span>
        <span className='s2'>공개합니다</span>
        <span className='s1'>
          .<span className='Apple-converted-space'> </span>
        </span>
      </p>
      <p className='p2'>
        <span className='s2'></span>
        <br />
      </p>
      <p className='p1'>
        <span className='s1'>I. </span>
        <span className='s2'>개인정보의</span>
        <span className='s1'> </span>
        <span className='s2'>처리목적</span>
        <span className='s1'>
          <span className='Apple-converted-space'> </span>
        </span>
      </p>
      <p className='p1'>
        <span className='s2'>사회과학대학</span>
        <span className='s1'> </span>
        <span className='s2'>학생회는</span>
        <span className='s1'> </span>
        <span className='s2'>다음의</span>
        <span className='s1'> </span>
        <span className='s2'>목적을</span>
        <span className='s1'> </span>
        <span className='s2'>위하여</span>
        <span className='s1'> </span>
        <span className='s2'>개인정보를</span>
        <span className='s1'> </span>
        <span className='s2'>처리합니다</span>
        <span className='s1'>. </span>
        <span className='s2'>처리하고</span>
        <span className='s1'> </span>
        <span className='s2'>있는</span>
        <span className='s1'> </span>
        <span className='s2'>개인정보는</span>
        <span className='s1'> </span>
        <span className='s2'>다음의</span>
        <span className='s1'> </span>
        <span className='s2'>목적</span>
        <span className='s1'> </span>
        <span className='s2'>이외의</span>
        <span className='s1'> </span>
        <span className='s2'>용도로는</span>
        <span className='s1'> </span>
        <span className='s2'>이용되지</span>
        <span className='s1'> </span>
        <span className='s2'>않으며</span>
        <span className='s1'>, </span>
        <span className='s2'>이용</span>
        <span className='s1'> </span>
        <span className='s2'>목적이</span>
        <span className='s1'> </span>
        <span className='s2'>변경되는</span>
        <span className='s1'> </span>
        <span className='s2'>경우에는</span>
        <span className='s1'> </span>
        <span className='s2'>「개인정보</span>
        <span className='s1'> </span>
        <span className='s2'>보호</span>
        <span className='s1'> </span>
        <span className='s2'>법」</span>
        <span className='s1'> </span>
        <span className='s2'>제</span>
        <span className='s1'>18</span>
        <span className='s2'>조에</span>
        <span className='s1'> </span>
        <span className='s2'>따라</span>
        <span className='s1'> </span>
        <span className='s2'>별도의</span>
        <span className='s1'> </span>
        <span className='s2'>동의를</span>
        <span className='s1'> </span>
        <span className='s2'>받는</span>
        <span className='s1'> </span>
        <span className='s2'>등</span>
        <span className='s1'> </span>
        <span className='s2'>필요한</span>
        <span className='s1'> </span>
        <span className='s2'>조치를</span>
        <span className='s1'> </span>
        <span className='s2'>이행할</span>
        <span className='s1'> </span>
        <span className='s2'>예정입니다</span>
        <span className='s1'>
          .<span className='Apple-converted-space'> </span>
        </span>
      </p>
      <p className='p2'>
        <span className='s2'></span>
        <br />
      </p>
      <p className='p1'>
        <span className='s1'>1. </span>
        <span className='s2'>쏙터디룸</span>
        <span className='s1'>(</span>
        <span className='s2'>경북대학교</span>
        <span className='s1'> </span>
        <span className='s2'>사회과학대학</span>
        <span className='s1'> </span>
        <span className='s2'>스터디룸</span>
        <span className='s1'>, </span>
        <span className='s2'>이하</span>
        <span className='s1'> </span>
        <span className='s2'>쏙터디룸이라고</span>
        <span className='s1'> </span>
        <span className='s2'>함</span>
        <span className='s1'>.) </span>
        <span className='s2'>예약자</span>
        <span className='s1'> </span>
        <span className='s2'>관리</span>
        <span className='s1'> </span>
        <span className='s2'>및</span>
        <span className='s1'> </span>
        <span className='s2'>관련</span>
        <span className='s1'> </span>
        <span className='s2'>서비스</span>
        <span className='s1'> </span>
        <span className='s2'>목적으로</span>
        <span className='s1'> </span>
        <span className='s2'>개인정보를</span>
        <span className='s1'> </span>
        <span className='s2'>처리합니다</span>
        <span className='s1'>
          .<span className='Apple-converted-space'> </span>
        </span>
      </p>
      <p className='p2'>
        <span className='s2'></span>
        <br />
      </p>
      <p className='p1'>
        <span className='s1'>2. </span>
        <span className='s2'>쏙터디룸</span>
        <span className='s1'> </span>
        <span className='s2'>시설</span>
        <span className='s1'> </span>
        <span className='s2'>관리에</span>
        <span className='s1'> </span>
        <span className='s2'>있어서</span>
        <span className='s1'> </span>
        <span className='s2'>해당하는</span>
        <span className='s1'> </span>
        <span className='s2'>날에</span>
        <span className='s1'> </span>
        <span className='s2'>사용한</span>
        <span className='s1'> </span>
        <span className='s2'>인원</span>
        <span className='s1'> </span>
        <span className='s2'>파악</span>
        <span className='s1'> </span>
        <span className='s2'>목적으로</span>
        <span className='s1'> </span>
        <span className='s2'>개인정보를</span>
        <span className='s1'> </span>
        <span className='s2'>처리합니</span>
        <span className='s1'> </span>
        <span className='s2'>다</span>
        <span className='s1'>
          .<span className='Apple-converted-space'> </span>
        </span>
      </p>
      <p className='p2'>
        <span className='s2'></span>
        <br />
      </p>
      <p className='p1'>
        <span className='s1'>II. </span>
        <span className='s2'>개인정보의</span>
        <span className='s1'> </span>
        <span className='s2'>처리</span>
        <span className='s1'> </span>
        <span className='s2'>보유기간</span>
        <span className='s1'>
          <br />
          1.{' '}
        </span>
        <span className='s2'>사회과학대학</span>
        <span className='s1'> </span>
        <span className='s2'>학생회는</span>
        <span className='s1'> </span>
        <span className='s2'>법령에</span>
        <span className='s1'> </span>
        <span className='s2'>따른</span>
        <span className='s1'> </span>
        <span className='s2'>개인정보</span>
        <span className='s1'> </span>
        <span className='s2'>보유</span>
        <span className='s1'>·</span>
        <span className='s2'>이용기간</span>
        <span className='s1'> </span>
        <span className='s2'>또는</span>
        <span className='s1'> </span>
        <span className='s2'>정보주체로부터</span>
        <span className='s1'> </span>
        <span className='s2'>개인정보를</span>
        <span className='s1'>
          <span className='Apple-converted-space'> </span>
        </span>
      </p>
      <p className='p1'>
        <span className='s2'>수집</span>
        <span className='s1'> </span>
        <span className='s2'>시에</span>
        <span className='s1'> </span>
        <span className='s2'>동의</span>
        <span className='s1'> </span>
        <span className='s2'>받은</span>
        <span className='s1'> </span>
        <span className='s2'>개인정보</span>
        <span className='s1'> </span>
        <span className='s2'>보유</span>
        <span className='s1'>·</span>
        <span className='s2'>이용기간</span>
        <span className='s1'> </span>
        <span className='s2'>내에서</span>
        <span className='s1'> </span>
        <span className='s2'>개인정보를</span>
        <span className='s1'> </span>
        <span className='s2'>처리</span>
        <span className='s1'>·</span>
        <span className='s2'>보유합니다</span>
        <span className='s1'>.</span>
      </p>
      <p className='p1'>
        <span className='s1'>
          <br />
          2.{' '}
        </span>
        <span className='s2'>각각의</span>
        <span className='s1'> </span>
        <span className='s2'>개인정보</span>
        <span className='s1'> </span>
        <span className='s2'>처리</span>
        <span className='s1'> </span>
        <span className='s2'>및</span>
        <span className='s1'> </span>
        <span className='s2'>보유</span>
        <span className='s1'> </span>
        <span className='s2'>기간은</span>
        <span className='s1'> </span>
        <span className='s2'>다음과</span>
        <span className='s1'> </span>
        <span className='s2'>같습니다</span>
        <span className='s1'>.</span>
      </p>
      <p className='p1'>
        <span className='s1'>
          <br />
          1){' '}
        </span>
        <span className='s2'>쏙터디룸</span>
        <span className='s1'> </span>
        <span className='s2'>예약자</span>
        <span className='s1'> </span>
        <span className='s2'>관리</span>
        <span className='s1'> : </span>
        <span className='s2'>법령이</span>
        <span className='s1'> </span>
        <span className='s2'>정하는</span>
        <span className='s1'> </span>
        <span className='s2'>경우를</span>
        <span className='s1'> </span>
        <span className='s2'>제외하고는</span>
        <span className='s1'> </span>
        <span className='s2'>회원</span>
        <span className='s1'> </span>
        <span className='s2'>탈퇴</span>
        <span className='s1'> </span>
        <span className='s2'>시</span>
        <span className='s1'> </span>
        <span className='s2'>까지</span>
        <span className='s1'> </span>
        <span className='s2'>보유</span>
        <span className='s1'> </span>
        <span className='s2'>및</span>
        <span className='s1'> </span>
        <span className='s2'>이용</span>
        <span className='s1'>
          <span className='Apple-converted-space'> </span>
        </span>
      </p>
      <p className='p1'>
        <span className='s1'>2) </span>
        <span className='s2'>쏙터디룸</span>
        <span className='s1'> </span>
        <span className='s2'>시설</span>
        <span className='s1'> </span>
        <span className='s2'>관리</span>
        <span className='s1'> : </span>
        <span className='s2'>법령이</span>
        <span className='s1'> </span>
        <span className='s2'>정하는</span>
        <span className='s1'> </span>
        <span className='s2'>경우를</span>
        <span className='s1'> </span>
        <span className='s2'>제외하고는</span>
        <span className='s1'> </span>
        <span className='s2'>회원</span>
        <span className='s1'> </span>
        <span className='s2'>탈퇴</span>
        <span className='s1'> </span>
        <span className='s2'>시</span>
        <span className='s1'> </span>
        <span className='s2'>까지</span>
        <span className='s1'> </span>
        <span className='s2'>보유</span>
        <span className='s1'> </span>
        <span className='s2'>및</span>
        <span className='s1'> </span>
        <span className='s2'>이용</span>
        <span className='s1'>
          <span className='Apple-converted-space'> </span>
        </span>
      </p>
      <p className='p1'>
        <span className='s1'>3) </span>
        <span className='s2'>졸업한</span>
        <span className='s1'> </span>
        <span className='s2'>학습자의</span>
        <span className='s1'> </span>
        <span className='s2'>정보</span>
        <span className='s1'> : </span>
        <span className='s2'>법령이</span>
        <span className='s1'> </span>
        <span className='s2'>정하는</span>
        <span className='s1'> </span>
        <span className='s2'>경우를</span>
        <span className='s1'> </span>
        <span className='s2'>제외하고는</span>
        <span className='s1'> </span>
        <span className='s2'>졸업</span>
        <span className='s1'> </span>
        <span className='s2'>이후</span>
        <span className='s1'> </span>
        <span className='s2'>지체</span>
        <span className='s1'> </span>
        <span className='s2'>없이</span>
        <span className='s1'> </span>
        <span className='s2'>파기</span>
        <span className='s1'>
          <span className='Apple-converted-space'> </span>
        </span>
      </p>
      <p className='p2'>
        <span className='s2'></span>
        <br />
      </p>
      <p className='p1'>
        <span className='s1'>III. </span>
        <span className='s2'>처리하는</span>
        <span className='s1'> </span>
        <span className='s2'>개인정보</span>
        <span className='s1'> </span>
        <span className='s2'>항목</span>
        <span className='s1'>
          <br />
          1.{' '}
        </span>
        <span className='s2'>사회과학대학</span>
        <span className='s1'> </span>
        <span className='s2'>학생회는</span>
        <span className='s1'> </span>
        <span className='s2'>다음의</span>
        <span className='s1'> </span>
        <span className='s2'>개인정보</span>
        <span className='s1'> </span>
        <span className='s2'>항목을</span>
        <span className='s1'> </span>
        <span className='s2'>처리하고</span>
        <span className='s1'> </span>
        <span className='s2'>있습니다</span>
        <span className='s1'>
          .<span className='Apple-converted-space'> </span>
        </span>
      </p>
      <p className='p3'>
        <span className='s2'></span>
        <br />
      </p>
      <p className='p1'>
        <span className='s1'>1) </span>
        <span className='s2'>예약자</span>
        <span className='s1'> </span>
        <span className='s2'>관리</span>
        <span className='s1'>
          <span className='Apple-converted-space'> </span>
        </span>
      </p>
      <p className='p1'>
        <span className='s1'>• </span>
        <span className='s2'>필수항목</span>
        <span className='s1'> : </span>
        <span className='s2'>이름</span>
        <span className='s1'>, </span>
        <span className='s2'>학과</span>
        <span className='s1'>, </span>
        <span className='s2'>학번</span>
        <span className='s1'>, </span>
        <span className='s2'>휴대전화번호</span>
        <span className='s1'>, </span>
        <span className='s2'>이메일</span>
        <span className='s1'>
          <span className='Apple-converted-space'> </span>
        </span>
      </p>
      <p className='p3'>
        <span className='s2'></span>
        <br />
      </p>
      <p className='p1'>
        <span className='s1'>2) </span>
        <span className='s2'>시설물</span>
        <span className='s1'> </span>
        <span className='s2'>관리에</span>
        <span className='s1'> </span>
        <span className='s2'>있어</span>
        <span className='s1'> </span>
        <span className='s2'>인원</span>
        <span className='s1'> </span>
        <span className='s2'>파악</span>
        <span className='s1'>
          <br />•{' '}
        </span>
        <span className='s2'>필수항목</span>
        <span className='s1'> : </span>
        <span className='s2'>이름</span>
        <span className='s1'>, </span>
        <span className='s2'>학과</span>
        <span className='s1'>, </span>
        <span className='s2'>학번</span>
        <span className='s1'>, </span>
        <span className='s2'>휴대전화번호</span>
        <span className='s1'>, </span>
        <span className='s2'>이메일</span>
        <span className='s1'>
          <span className='Apple-converted-space'> </span>
        </span>
      </p>
      <p className='p2'>
        <span className='s2'></span>
        <br />
      </p>
      <p className='p1'>
        <span className='s1'>IV. </span>
        <span className='s2'>개인정보의</span>
        <span className='s1'> </span>
        <span className='s2'>제</span>
        <span className='s1'>3</span>
        <span className='s2'>자</span>
        <span className='s1'> </span>
        <span className='s2'>제공</span>
        <span className='s1'>
          <span className='Apple-converted-space'> </span>
        </span>
      </p>
      <p className='p1'>
        <span className='s1'>1. </span>
        <span className='s2'>사회과학대학</span>
        <span className='s1'> </span>
        <span className='s2'>학생회는</span>
        <span className='s1'> </span>
        <span className='s2'>정보주체의</span>
        <span className='s1'> </span>
        <span className='s2'>개인정보를</span>
        <span className='s1'> </span>
        <span className='s2'>개인정보의</span>
        <span className='s1'> </span>
        <span className='s2'>처리</span>
        <span className='s1'> </span>
        <span className='s2'>목적에서</span>
        <span className='s1'> </span>
        <span className='s2'>명시한</span>
        <span className='s1'> </span>
        <span className='s2'>범위</span>
        <span className='s1'> </span>
        <span className='s2'>내에서</span>
        <span className='s1'> </span>
        <span className='s2'>만</span>
        <span className='s1'> </span>
        <span className='s2'>처리하며</span>
        <span className='s1'>, </span>
        <span className='s2'>정보주체의</span>
        <span className='s1'> </span>
        <span className='s2'>동의</span>
        <span className='s1'>, </span>
        <span className='s2'>법률의</span>
        <span className='s1'> </span>
        <span className='s2'>특별한</span>
        <span className='s1'> </span>
        <span className='s2'>규정</span>
        <span className='s1'> </span>
        <span className='s2'>등</span>
        <span className='s1'> </span>
        <span className='s2'>「개인정보</span>
        <span className='s1'> </span>
        <span className='s2'>보호법」</span>
        <span className='s1'> </span>
        <span className='s2'>제</span>
        <span className='s1'>17</span>
        <span className='s2'>조</span>
        <span className='s1'> </span>
        <span className='s2'>및</span>
        <span className='s1'> </span>
        <span className='s2'>제</span>
        <span className='s1'>18</span>
        <span className='s2'>조에</span>
        <span className='s1'> </span>
        <span className='s2'>해당하는</span>
        <span className='s1'> </span>
        <span className='s2'>경우에만</span>
        <span className='s1'> </span>
        <span className='s2'>개인정보를</span>
        <span className='s1'> </span>
        <span className='s2'>제</span>
        <span className='s1'>3</span>
        <span className='s2'>자에게</span>
        <span className='s1'> </span>
        <span className='s2'>제공하고</span>
        <span className='s1'> </span>
        <span className='s2'>그</span>
        <span className='s1'> </span>
        <span className='s2'>이외에는</span>
        <span className='s1'> </span>
        <span className='s2'>정보주체의</span>
        <span className='s1'> </span>
        <span className='s2'>개인정보를</span>
        <span className='s1'> </span>
        <span className='s2'>제</span>
        <span className='s1'>3</span>
        <span className='s2'>자에</span>
        <span className='s1'> </span>
        <span className='s2'>게</span>
        <span className='s1'> </span>
        <span className='s2'>제공하지</span>
        <span className='s1'> </span>
        <span className='s2'>않습니다</span>
        <span className='s1'>
          .<span className='Apple-converted-space'> </span>
        </span>
      </p>
      <p className='p1'>
        <span className='s1'>2 . </span>
        <span className='s2'>사회과학대학</span>
        <span className='s1'> </span>
        <span className='s2'>학생회는</span>
        <span className='s1'> </span>
        <span className='s2'>원활한</span>
        <span className='s1'> </span>
        <span className='s2'>서비스</span>
        <span className='s1'> </span>
        <span className='s2'>제공을</span>
        <span className='s1'> </span>
        <span className='s2'>위해</span>
        <span className='s1'> </span>
        <span className='s2'>사회과학대학</span>
        <span className='s1'> </span>
        <span className='s2'>행정실에</span>
        <span className='s1'> </span>
        <span className='s2'>개인정보를</span>
        <span className='s1'> </span>
        <span className='s2'>제공할</span>
        <span className='s1'> </span>
        <span className='s2'>수</span>
        <span className='s1'> </span>
        <span className='s2'>있으며</span>
        <span className='s1'>, </span>
        <span className='s2'>이</span>
        <span className='s1'> </span>
        <span className='s2'>경우</span>
        <span className='s1'> </span>
        <span className='s2'>정보주체의</span>
        <span className='s1'> </span>
        <span className='s2'>동의를</span>
        <span className='s1'> </span>
        <span className='s2'>얻어</span>
        <span className='s1'> </span>
        <span className='s2'>필요</span>
        <span className='s1'> </span>
        <span className='s2'>최소한의</span>
        <span className='s1'> </span>
        <span className='s2'>범위로만</span>
        <span className='s1'> </span>
        <span className='s2'>제공합니다</span>
        <span className='s1'>
          .<span className='Apple-converted-space'> </span>
        </span>
      </p>
      <p className='p1'>
        <span className='s2'>제공받는</span>
        <span className='s1'> </span>
        <span className='s2'>자</span>
        <span className='s1'> : </span>
        <span className='s2'>사회과학대학</span>
        <span className='s1'> </span>
        <span className='s2'>행정실</span>
        <span className='s1'> </span>
        <span className='s2'>제공목적</span>
        <span className='s1'> : </span>
        <span className='s2'>시설</span>
        <span className='s1'> </span>
        <span className='s2'>관리</span>
        <span className='s1'>
          <br />
        </span>
        <span className='s2'>제공항목</span>
        <span className='s1'> : </span>
        <span className='s2'>이름</span>
        <span className='s1'>, </span>
        <span className='s2'>학과</span>
        <span className='s1'>, </span>
        <span className='s2'>학번</span>
        <span className='s1'>, </span>
        <span className='s2'>휴대전화번호</span>
        <span className='s1'> </span>
        <span className='s2'>보유</span>
        <span className='s1'> </span>
        <span className='s2'>및</span>
        <span className='s1'> </span>
        <span className='s2'>이용기간</span>
        <span className='s1'> : </span>
        <span className='s2'>이용</span>
        <span className='s1'> </span>
        <span className='s2'>목적</span>
        <span className='s1'> </span>
        <span className='s2'>달성</span>
        <span className='s1'> </span>
        <span className='s2'>시까지</span>
        <span className='s1'>
          <span className='Apple-converted-space'> </span>
        </span>
      </p>
      <p className='p1'>
        <span className='s1'>V. </span>
        <span className='s2'>개인정보의</span>
        <span className='s1'> </span>
        <span className='s2'>파기</span>
        <span className='s1'> </span>
        <span className='s2'>절차</span>
        <span className='s1'> </span>
        <span className='s2'>및</span>
        <span className='s1'> </span>
        <span className='s2'>방법</span>
        <span className='s1'>
          <br />
          1.{' '}
        </span>
        <span className='s2'>사회과학대학</span>
        <span className='s1'> </span>
        <span className='s2'>학생회는</span>
        <span className='s1'> </span>
        <span className='s2'>개인정보</span>
        <span className='s1'> </span>
        <span className='s2'>보유기간의</span>
        <span className='s1'> </span>
        <span className='s2'>경과</span>
        <span className='s1'>, </span>
        <span className='s2'>처리목적</span>
        <span className='s1'> </span>
        <span className='s2'>달성</span>
        <span className='s1'> </span>
        <span className='s2'>등</span>
        <span className='s1'> </span>
        <span className='s2'>개인정보가</span>
        <span className='s1'> </span>
        <span className='s2'>불필요하게</span>
        <span className='s1'>
          <span className='Apple-converted-space'> </span>
        </span>
      </p>
      <p className='p1'>
        <span className='s2'>되었을</span>
        <span className='s1'> </span>
        <span className='s2'>때에는</span>
        <span className='s1'> </span>
        <span className='s2'>지체없이</span>
        <span className='s1'> </span>
        <span className='s2'>해당</span>
        <span className='s1'> </span>
        <span className='s2'>개인정보를</span>
        <span className='s1'> </span>
        <span className='s2'>파기합니다</span>
        <span className='s1'>.</span>
      </p>
      <p className='p1'>
        <span className='s1'>
          <br />
          2.{' '}
        </span>
        <span className='s2'>개인정보</span>
        <span className='s1'> </span>
        <span className='s2'>파기의</span>
        <span className='s1'> </span>
        <span className='s2'>절차</span>
        <span className='s1'> </span>
        <span className='s2'>및</span>
        <span className='s1'> </span>
        <span className='s2'>방법은</span>
        <span className='s1'> </span>
        <span className='s2'>다음과</span>
        <span className='s1'> </span>
        <span className='s2'>같습니다</span>
        <span className='s1'>
          .<br />
          1){' '}
        </span>
        <span className='s2'>파기절차</span>
        <span className='s1'>: </span>
        <span className='s2'>사회과학대학</span>
        <span className='s1'> </span>
        <span className='s2'>학생회는</span>
        <span className='s1'> </span>
        <span className='s2'>파기</span>
        <span className='s1'> </span>
        <span className='s2'>사유가</span>
        <span className='s1'> </span>
        <span className='s2'>발생한</span>
        <span className='s1'> </span>
        <span className='s2'>개인정보를</span>
        <span className='s1'> </span>
        <span className='s2'>선정하고</span>
        <span className='s1'> </span>
        <span className='s2'>파기합니다</span>
        <span className='s1'>
          .<span className='Apple-converted-space'> </span>
        </span>
      </p>
      <p className='p1'>
        <span className='s1'>2) </span>
        <span className='s2'>파기방법</span>
        <span className='s1'>: </span>
        <span className='s2'>사회과학대학</span>
        <span className='s1'> </span>
        <span className='s2'>학생회는</span>
        <span className='s1'> </span>
        <span className='s2'>전자적</span>
        <span className='s1'> </span>
        <span className='s2'>파일</span>
        <span className='s1'> </span>
        <span className='s2'>형태로</span>
        <span className='s1'> </span>
        <span className='s2'>기록</span>
        <span className='s1'>·</span>
        <span className='s2'>저장된</span>
        <span className='s1'> </span>
        <span className='s2'>개인정보는</span>
        <span className='s1'> </span>
        <span className='s2'>기록을</span>
        <span className='s1'> </span>
        <span className='s2'>재생할</span>
        <span className='s1'> </span>
        <span className='s2'>수</span>
        <span className='s1'> </span>
        <span className='s2'>없도록</span>
        <span className='s1'> </span>
        <span className='s2'>파기하며</span>
        <span className='s1'>, </span>
        <span className='s2'>종이</span>
        <span className='s1'> </span>
        <span className='s2'>문서에</span>
        <span className='s1'> </span>
        <span className='s2'>기록</span>
        <span className='s1'>·</span>
        <span className='s2'>저장된</span>
        <span className='s1'> </span>
        <span className='s2'>개인정보는</span>
        <span className='s1'> </span>
        <span className='s2'>분쇄기로</span>
        <span className='s1'> </span>
        <span className='s2'>분쇄하거나</span>
        <span className='s1'> </span>
        <span className='s2'>소각하여</span>
        <span className='s1'> </span>
        <span className='s2'>파기합</span>
        <span className='s1'> </span>
        <span className='s2'>니다</span>
        <span className='s1'>
          .<span className='Apple-converted-space'> </span>
        </span>
      </p>
      <p className='p2'>
        <span className='s2'></span>
        <br />
      </p>
      <p className='p1'>
        <span className='s1'>VI. </span>
        <span className='s2'>권익침해</span>
        <span className='s1'> </span>
        <span className='s2'>구제방법</span>
        <span className='s1'>
          <span className='Apple-converted-space'> </span>
        </span>
      </p>
      <p className='p1'>
        <span className='s1'>1. </span>
        <span className='s2'>정보주체는</span>
        <span className='s1'> </span>
        <span className='s2'>개인정보침해로</span>
        <span className='s1'> </span>
        <span className='s2'>인한</span>
        <span className='s1'> </span>
        <span className='s2'>구제를</span>
        <span className='s1'> </span>
        <span className='s2'>받기</span>
        <span className='s1'> </span>
        <span className='s2'>위하여</span>
        <span className='s1'> </span>
        <span className='s2'>개인정보분쟁조정위원회</span>
        <span className='s1'>, </span>
        <span className='s2'>한국인터넷진</span>
        <span className='s1'> </span>
        <span className='s2'>흥원</span>
        <span className='s1'> </span>
        <span className='s2'>개인정보침해신고센터</span>
        <span className='s1'> </span>
        <span className='s2'>등에</span>
        <span className='s1'> </span>
        <span className='s2'>분쟁해결이나</span>
        <span className='s1'> </span>
        <span className='s2'>상담</span>
        <span className='s1'> </span>
        <span className='s2'>등을</span>
        <span className='s1'> </span>
        <span className='s2'>신청할</span>
        <span className='s1'> </span>
        <span className='s2'>수</span>
        <span className='s1'> </span>
        <span className='s2'>있습니다</span>
        <span className='s1'>. </span>
        <span className='s2'>이</span>
        <span className='s1'> </span>
        <span className='s2'>밖에</span>
        <span className='s1'> </span>
        <span className='s2'>기타</span>
        <span className='s1'> </span>
        <span className='s2'>개</span>
        <span className='s1'>
          <span className='Apple-converted-space'> </span>
        </span>
      </p>
      <p className='p1'>
        <span className='s2'>인정보</span>
        <span className='s1'> </span>
        <span className='s2'>침해의</span>
        <span className='s1'> </span>
        <span className='s2'>신고</span>
        <span className='s1'>, </span>
        <span className='s2'>상담에</span>
        <span className='s1'> </span>
        <span className='s2'>대하여는</span>
        <span className='s1'> </span>
        <span className='s2'>아래의</span>
        <span className='s1'> </span>
        <span className='s2'>기관에</span>
        <span className='s1'> </span>
        <span className='s2'>문의하시기</span>
        <span className='s1'> </span>
        <span className='s2'>바랍니다</span>
        <span className='s1'>
          .<span className='Apple-converted-space'> </span>
        </span>
      </p>
      <p className='p4'>
        <span className='s2'>1) </span>
        <span className='s3'>개인정보분쟁조정위원회</span>
        <span className='s2'> : (</span>
        <span className='s3'>국번없이</span>
        <span className='s2'>) 1833-6972 (</span>
        <span className='s4'>www.kopico.go.kr</span>
        <span className='s2'>
          )<br />
          2){' '}
        </span>
        <span className='s3'>개인정보침해신고센터</span>
        <span className='s2'> : (</span>
        <span className='s3'>국번없이</span>
        <span className='s2'>
          ) 118 (privacy.kisa.or.kr)
          <br />
          3){' '}
        </span>
        <span className='s3'>대검찰청</span>
        <span className='s2'> : (</span>
        <span className='s3'>국번없이</span>
        <span className='s2'>) 1301 (</span>
        <span className='s4'>www.spo.go.kr</span>
        <span className='s2'>
          )<span className='Apple-converted-space'> </span>
        </span>
      </p>
      <p className='p4'>
        <span className='s2'>4) </span>
        <span className='s3'>경찰청</span>
        <span className='s2'> : (</span>
        <span className='s3'>국번없이</span>
        <span className='s2'>
          ) 182 (ecrm.cyber.go.kr)
          <span className='Apple-converted-space'> </span>
        </span>
      </p>
      <p className='p2'>
        <span className='s2'></span>
        <br />
      </p>
      <p className='p1'>
        <span className='s1'>2. </span>
        <span className='s2'>사회과학대학</span>
        <span className='s1'> </span>
        <span className='s2'>학생회는</span>
        <span className='s1'> </span>
        <span className='s2'>정보주체의</span>
        <span className='s1'> </span>
        <span className='s2'>개인정보자기결정권을</span>
        <span className='s1'> </span>
        <span className='s2'>보장하고</span>
        <span className='s1'>, </span>
        <span className='s2'>개인정보침해로</span>
        <span className='s1'> </span>
        <span className='s2'>인한</span>
        <span className='s1'> </span>
        <span className='s2'>상</span>
        <span className='s1'> </span>
        <span className='s2'>담</span>
        <span className='s1'> </span>
        <span className='s2'>및</span>
        <span className='s1'> </span>
        <span className='s2'>피해</span>
        <span className='s1'> </span>
        <span className='s2'>구제를</span>
        <span className='s1'> </span>
        <span className='s2'>위해</span>
        <span className='s1'> </span>
        <span className='s2'>노력하고</span>
        <span className='s1'> </span>
        <span className='s2'>있으며</span>
        <span className='s1'>, </span>
        <span className='s2'>신고나</span>
        <span className='s1'> </span>
        <span className='s2'>상담이</span>
        <span className='s1'> </span>
        <span className='s2'>필요한</span>
        <span className='s1'> </span>
        <span className='s2'>경우</span>
        <span className='s1'> </span>
        <span className='s2'>사회과학대학</span>
        <span className='s1'> </span>
        <span className='s2'>학생회로</span>
        <span className='s1'> </span>
        <span className='s2'>연</span>
        <span className='s1'> </span>
        <span className='s2'>락해</span>
        <span className='s1'> </span>
        <span className='s2'>주시기</span>
        <span className='s1'> </span>
        <span className='s2'>바랍니다</span>
        <span className='s1'>
          .<span className='Apple-converted-space'> </span>
        </span>
      </p>
      <p className='p1'>
        <br />
        <span className='s5'>‣</span>
        <span className='s1'> </span>
        <span className='s2'>개발자</span>
        <span className='s1'> </span>
        <span className='s2'>정보</span>
        <br />
        <span className='s2'>담당자: 전우진</span>
        <br />
        <span className='s2'>연락처: 010-9844-2434</span>
        <br />
        <span className='s2'>이메일: junwoojin960@naver.com</span>
        <br />
      </p>
      <p className='p2'>
        <span className='s2'></span>
        <br />
      </p>
      <p className='p1'>
        <span className='s1'>3. </span>
        <span className='s2'>「개인정보</span>
        <span className='s1'> </span>
        <span className='s2'>보호법」</span>
        <span className='s1'> </span>
        <span className='s2'>제</span>
        <span className='s1'>35</span>
        <span className='s2'>조</span>
        <span className='s1'>(</span>
        <span className='s2'>개인정보의</span>
        <span className='s1'> </span>
        <span className='s2'>열람</span>
        <span className='s1'>), </span>
        <span className='s2'>제</span>
        <span className='s1'>36</span>
        <span className='s2'>조</span>
        <span className='s1'>(</span>
        <span className='s2'>개인정보의</span>
        <span className='s1'> </span>
        <span className='s2'>정정</span>
        <span className='s1'>·</span>
        <span className='s2'>삭제</span>
        <span className='s1'>), </span>
        <span className='s2'>제</span>
        <span className='s1'>37</span>
        <span className='s2'>조</span>
        <span className='s1'>(</span>
        <span className='s2'>개인정보의</span>
        <span className='s1'> </span>
        <span className='s2'>처리정지</span>
        <span className='s1'> </span>
        <span className='s2'>등</span>
        <span className='s1'>)</span>
        <span className='s2'>의</span>
        <span className='s1'> </span>
        <span className='s2'>규정에</span>
        <span className='s1'> </span>
        <span className='s2'>의한</span>
        <span className='s1'> </span>
        <span className='s2'>요구에</span>
        <span className='s1'> </span>
        <span className='s2'>대하여</span>
        <span className='s1'> </span>
        <span className='s2'>공공기관의</span>
        <span className='s1'> </span>
        <span className='s2'>장이</span>
        <span className='s1'> </span>
        <span className='s2'>행한</span>
        <span className='s1'> </span>
        <span className='s2'>처분</span>
        <span className='s1'> </span>
        <span className='s2'>또는</span>
        <span className='s1'> </span>
        <span className='s2'>부작위로</span>
        <span className='s1'> </span>
        <span className='s2'>인하여</span>
        <span className='s1'> </span>
        <span className='s2'>권</span>
        <span className='s1'> </span>
        <span className='s2'>리</span>
        <span className='s1'> </span>
        <span className='s2'>또는</span>
        <span className='s1'> </span>
        <span className='s2'>이익의</span>
        <span className='s1'> </span>
        <span className='s2'>침해를</span>
        <span className='s1'> </span>
        <span className='s2'>받은</span>
        <span className='s1'> </span>
        <span className='s2'>자는</span>
        <span className='s1'> </span>
        <span className='s2'>행정심판법이</span>
        <span className='s1'> </span>
        <span className='s2'>정하는</span>
        <span className='s1'> </span>
        <span className='s2'>바에</span>
        <span className='s1'> </span>
        <span className='s2'>따라</span>
        <span className='s1'> </span>
        <span className='s2'>행정심판을</span>
        <span className='s1'> </span>
        <span className='s2'>청구할</span>
        <span className='s1'> </span>
        <span className='s2'>수</span>
        <span className='s1'> </span>
        <span className='s2'>있습니다</span>
        <span className='s1'>
          .<span className='Apple-converted-space'> </span>
        </span>
      </p>
      <p className='p4'>
        <span className='s5'>‣</span>
        <span className='s2'> </span>
        <span className='s3'>중앙행정심판위원회</span>
        <span className='s2'> : (</span>
        <span className='s3'>국번없이</span>
        <span className='s2'>) 110 (</span>
        <span className='s4'>www.simpan.go.kr</span>
        <span className='s2'>
          )<span className='Apple-converted-space'> </span>
        </span>
      </p>
      <p className='p2'>
        <span className='s2'></span>
        <br />
      </p>
      <p className='p1'>
        <span className='s1'>VII. </span>
        <span className='s2'>개인정보</span>
        <span className='s1'> </span>
        <span className='s2'>처리방침의</span>
        <span className='s1'> </span>
        <span className='s2'>변경</span>
        <span className='s1'>
          <br />
          1.{' '}
        </span>
        <span className='s2'>이</span>
        <span className='s1'> </span>
        <span className='s2'>개인정보</span>
        <span className='s1'> </span>
        <span className='s2'>처리방침은</span>
        <span className='s1'> 2022. 5. X</span>
        <span className='s2'>부터</span>
        <span className='s1'> </span>
        <span className='s2'>적용됩니다</span>
        <span className='s1'>
          .<span className='Apple-converted-space'> </span>
        </span>
      </p>
    </div>
  );
};

export default Personal;
