import useAccessToken from '@hooks/useAccessToken';
import usePopUp from '@hooks/usePopUp';
import { apiRoute, requestDelete, requestGet } from '@libs/api/api';
import { MessageTypes } from '@libs/message/components/messages';
import {
  AUTO_LOGIN_MESSAGE_TYPE,
  ON_BACK_PRESSED_MESSAGE_TYPES,
} from '@libs/message/components/modules/messageTypes';
import {
  ReservationTypes,
  UserInfoTypes,
} from '@typedef/components/Home/home.types';
import React, { useCallback, useEffect } from 'react';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Home from '../Home';
import DeletePopupContainer from './DeletePopupContainer';
import ExitPopupContainer from './ExitPopupContainer';
import LogoutPopupContainer from './LogoutPopupContainer';
import NamePopupContainer from './NamePopupContainer';
import PasswordPopupContainer from './PasswordPopupContainer';
import StudentIdPopupContainer from './StudentIdPopupContainer';

const HomeContainer = () => {
  const navigate = useNavigate();
  const { __showPopUpFromHooks } = usePopUp();
  const [name, setName] = useState('홍길동');
  const [studentId, setStudentId] = useState('2020123456');
  const { accessToken, __updateAccessToken } = useAccessToken();
  const [reservationList, setReservationList] = useState<ReservationTypes[]>(
    [],
  );

  const onMessageHandler = useCallback((event: Event) => {
    const data =
      (event as any)?.data ||
      JSON.stringify({
        type: '@@ERROR_MESSAGE_TYPES',
        payload: {},
      });

    const { type, payload } = JSON.parse(data) as MessageTypes;
    switch (type) {
      case ON_BACK_PRESSED_MESSAGE_TYPES:
        onBackBtnClicked();
        return;
      case AUTO_LOGIN_MESSAGE_TYPE:
        __updateAccessToken(payload.accessToken);
        return;
    }
  }, []);

  const onBackBtnClicked = useCallback(() => {
    __showPopUpFromHooks(<ExitPopupContainer />);
  }, [navigate]);

  const onChangeNameClicked = useCallback(() => {
    __showPopUpFromHooks(<NamePopupContainer setName={setName} />);
  }, []);

  const onChangeStudentIdClicked = useCallback(() => {
    __showPopUpFromHooks(
      <StudentIdPopupContainer setStudentId={setStudentId} />,
    );
  }, []);

  const onChangePasswordClicked = useCallback(() => {
    __showPopUpFromHooks(<PasswordPopupContainer />);
  }, []);

  const onLogoutClicked = useCallback(() => {
    __showPopUpFromHooks(<LogoutPopupContainer />);
  }, []);

  const onDeleteClicked = useCallback(() => {
    __showPopUpFromHooks(<DeletePopupContainer />);
  }, []);

  const onReservationClicked = useCallback(() => {
    navigate('/reservation');
  }, [navigate]);

  const loadUserInfo = useCallback(async () => {
    const {
      data: { name, studentId },
      config: { statusCode },
    } = await requestGet<UserInfoTypes>(apiRoute.user.profile, {
      Authorization: `Bearer ${accessToken}`,
    });

    if (statusCode === 200) {
      setName(name);
      setStudentId(studentId);

      return;
    }

    console.log(statusCode);
  }, [accessToken]);

  const loadReservationList = useCallback(async () => {
    const {
      data,
      config: { statusCode },
    } = await requestGet<ReservationTypes[]>(apiRoute.reservation.getList, {
      Authorization: `Bearer ${accessToken}`,
    });

    console.log(data);
    const start = data[0].time.start;
    const end = data[data.length - 1].time.end;
    setReservationList([
      {
        room: data[0].room,
        time: { start, end },
        date: data[0].date,
      },
    ]);
  }, [accessToken]);

  const deleteReservation = useCallback(async () => {
    const {
      data,
      config: { statusCode },
    } = await requestDelete<void>(apiRoute.reservation.register, {
      Authorization: `Bearer ${accessToken}`,
    });

    if (statusCode !== 200) {
      console.error('delete faliled');
      return;
    }
    setReservationList([]);
  }, [reservationList]);

  const onReservationDeleted = useCallback(() => {
    deleteReservation();
  }, [deleteReservation]);

  useEffect(() => {
    document.addEventListener('message', onMessageHandler);
    window.addEventListener('message', onMessageHandler);

    return () => {
      document.removeEventListener('message', onMessageHandler);
      window.removeEventListener('message', onMessageHandler);
    };
  }, []);

  useEffect(() => {
    loadUserInfo();
    loadReservationList();
  }, [accessToken]);

  return (
    <Home
      reservationList={reservationList}
      name={name}
      setName={setName}
      studentId={studentId}
      setStudentId={setStudentId}
      onChangeNameClicked={onChangeNameClicked}
      onChangeStudentIdClicked={onChangeStudentIdClicked}
      onChangePasswordClicked={onChangePasswordClicked}
      onLogoutClicked={onLogoutClicked}
      onDeleteClicked={onDeleteClicked}
      onReservationClicked={onReservationClicked}
      onReservationDeleted={onReservationDeleted}
    />
  );
};

export default HomeContainer;
