import React, { RefObject } from 'react';
import '../styles/password.popup.styles.css';

type Props = {
  isChecked: boolean;
  setPassword: React.Dispatch<React.SetStateAction<string>>;
  setPasswordCheck: React.Dispatch<React.SetStateAction<string>>;
  onChangeClicked: () => void;
  onCancelClicked: () => void;
};

const PasswordPopup = ({
  isChecked,
  setPassword,
  setPasswordCheck,
  onChangeClicked,
  onCancelClicked,
}: Props) => {
  return (
    <div className='password-popup-root'>
      <div className='popup-header'>
        <span>비밀번호 변경</span>
      </div>
      <div className='popup-content'>
        <input
          type='password'
          placeholder='사용하실 비밀번호를 입력해주세요'
          onChange={(e) => setPassword(e.target.value)}
        />
        <input
          type='password'
          placeholder='비밀번호를 한 번 더 입력해주세요'
          onChange={(e) => setPasswordCheck(e.target.value)}
        />
        {!isChecked && (
          <span className='error-msg'>비밀번호가 일치하지 않습니다</span>
        )}
      </div>
      <div className='popup-footer'>
        <button className='cancel-button' onClick={onCancelClicked}>
          취소
        </button>
        <button className='change-button' onClick={onChangeClicked}>
          변경
        </button>
      </div>
    </div>
  );
};

export default PasswordPopup;
