import usePopUp from '@hooks/usePopUp';
import { logoutMessage } from '@libs/message/components/messages';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import LogoutPopup from '../components/LogoutPopup';

const LogoutPopupContainer = () => {
  const navigation = useNavigate();
  const { __hidePopupFromHooks } = usePopUp();
  const onCancelClicked = useCallback(() => {
    __hidePopupFromHooks();
  }, []);

  const onLogoutClicked = useCallback(async () => {
    window.ReactNativeWebView.postMessage(JSON.stringify(logoutMessage()));
    __hidePopupFromHooks();
  }, [navigation]);

  return (
    <LogoutPopup
      onCancelClicked={onCancelClicked}
      onLogoutClicked={onLogoutClicked}
    />
  );
};

export default LogoutPopupContainer;
