import React from 'react';
import '../styles/register.popup.css';

type Props = {
  onCancelClicked: () => void;
  onRegisterClicked: () => void;
};

const RegisterPopup = ({ onCancelClicked, onRegisterClicked }: Props) => {
  return (
    <div className='register-popup-root'>
      <div className='popup-header'>
        <span>예약 등록</span>
      </div>
      <div className='popup-content'>
        <span>예약을 등록 하시겠습니까?</span>
      </div>

      <div className='popup-footer'>
        <button className='cancel-button' onClick={onCancelClicked}>
          취소
        </button>
        <button className='change-button' onClick={onRegisterClicked}>
          확인
        </button>
      </div>
    </div>
  );
};

export default RegisterPopup;
