import images from 'assets/images';
import React, { RefObject } from 'react';
import './styles/cinput.styles.css';

type Props = {
  onError: {
    error: boolean;
    alertMessage: string;
  };
  placeHolder: string;
  onInputChanged: (val: string) => void;
  type: React.HTMLInputTypeAttribute;
  inputRef: RefObject<HTMLInputElement>;
  disabled: boolean;
};

const CInput = ({
  onError,
  placeHolder,
  onInputChanged,
  type,
  inputRef,
  disabled,
}: Props) => {
  return (
    <div className='cinput-root'>
      <input
        ref={inputRef}
        className='custom-input cinput-input'
        type={type}
        placeholder={placeHolder}
        onChange={(e) => onInputChanged(e.target.value)}
        disabled={disabled}
      />
      {onError.error && (
        <span className='cinput-alert-message'>{onError.alertMessage}</span>
      )}
      {onError.error && (
        <img
          className='cinput-error-icon'
          src={images.common.error}
          alt='CInput Error Icon'
        />
      )}
    </div>
  );
};

export default CInput;
