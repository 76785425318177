import usePopUp from '@hooks/usePopUp';
import RootNavigation from '@routes/RootNavigation';
import React from 'react';

const RootNavigationContainer = () => {
  const { popUp } = usePopUp();

  return <RootNavigation popUp={popUp} />;
};

export default RootNavigationContainer;
