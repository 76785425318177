import usePopUp from '@hooks/usePopUp';
import React, { useCallback } from 'react';
import DateAlertPopup from '../components/DateAlertPopup';

type Props = {
  title: string;
  content: string;
  onConfirmClicked: () => void;
};

const DateAlertPopupContainer = ({
  title,
  content,
  onConfirmClicked,
}: Props) => {
  const { __hidePopupFromHooks } = usePopUp();

  return (
    <DateAlertPopup
      title={title}
      content={content}
      onConfirmClicked={onConfirmClicked}
    />
  );
};

export default DateAlertPopupContainer;
