export function twoDigits(num: number) {
  return num >= 10 ? num.toString() : `0${num}`;
}

export function timeToClock(time: string) {
  return parseInt(time.split(':')[0]);
}

export function clockToTime(clock: number) {
  return `${twoDigits(clock)}:00`;
}
