import CHeaderContainer from '@components/common/CHeader/containers/CHeaderContainer';
import CInputContainer from '@components/common/CInput/containers/CInputContainer';
import React from 'react';
import './styles/signup.styles.css';

type Props = {
  setName: React.Dispatch<React.SetStateAction<string>>;
  setStuId: React.Dispatch<React.SetStateAction<string>>;
  needStuIdCheck: boolean;
  setNeedStuIdCheck: React.Dispatch<React.SetStateAction<boolean>>;
  isStuIdUnique: boolean;
  email: string;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  needEmailCheck: boolean;
  onEmailConfirmNeeded: () => void;
  isEmailUnique: boolean;
  isKnuEmail: boolean;
  isEmailReadyToVerify: boolean;
  onEmailVerifyClicked: () => void;
  isVerifyMode: boolean;
  setVerifyCode: React.Dispatch<React.SetStateAction<string>>;
  onVerifyCodeConfirmClicked: () => void;
  isEmailVerified: boolean;
  setPw: React.Dispatch<React.SetStateAction<string>>;
  needPwConfirm: boolean;
  setPwConfirm: React.Dispatch<React.SetStateAction<string>>;
  onPwConfirmNeeded: () => void;
  passwordConfirmed: boolean;
  doneBtnActive: boolean;
  onDoneBtnClicked: () => void;
};

const SignUp = ({
  setName,
  setStuId,
  isStuIdUnique,
  needStuIdCheck,
  setNeedStuIdCheck,
  setEmail,
  needEmailCheck,
  onEmailConfirmNeeded,
  isEmailUnique,
  isKnuEmail,
  isEmailReadyToVerify,
  onEmailVerifyClicked,
  isVerifyMode,
  setVerifyCode,
  onVerifyCodeConfirmClicked,
  isEmailVerified,
  setPw,
  needPwConfirm,
  setPwConfirm,
  onPwConfirmNeeded,
  passwordConfirmed,
  doneBtnActive,
  onDoneBtnClicked,
}: Props) => {
  return (
    <div className='sign-up-root'>
      <CHeaderContainer title='회원가입' />
      <div className='content-root'>
        <div className='input-item'>
          <span className='input-label'>이름</span>
          <CInputContainer
            onError={{
              error: false,
              alertMessage: 'THERE IS NO ERROR',
            }}
            placeHolder='이름을 입력해주세요'
            onInputChanged={(val) => setName(val)}
            type='text'
          />
        </div>
        <div className='input-item'>
          <span className='input-label'>학번</span>
          <CInputContainer
            onError={{
              error: needStuIdCheck && !isStuIdUnique,
              alertMessage: '이미 가입되어 있는 학번 입니다',
            }}
            placeHolder='학번을 입력해주세요'
            onInputChanged={(val) => {
              setStuId(val);
              setNeedStuIdCheck(true);
            }}
            type='number'
          />
        </div>
        <div className='input-item input-email'>
          <div className='input-label'>이메일</div>
          <CInputContainer
            onError={{
              error: needEmailCheck && !(isKnuEmail && isEmailUnique),
              alertMessage: isKnuEmail
                ? '이미 가입되어 있는 이메일 입니다'
                : '경북대학교 웹메일 형식이 아닙니다',
            }}
            placeHolder='이메일을 입력해주세요'
            onInputChanged={(val) => {
              setEmail(val);
              onEmailConfirmNeeded();
            }}
            type='text'
            disabled={isEmailVerified}
          />
          {isEmailReadyToVerify && !isEmailVerified ? (
            <span
              onClick={onEmailVerifyClicked}
              className='email-verify-btn active'>
              인증하기
            </span>
          ) : null}
        </div>
        {isVerifyMode && (
          <div className='input-item input-verify-code'>
            <span className='input-label'>인증코드</span>
            <CInputContainer
              onError={{
                error: false,
                alertMessage: 'THERE IS NO ERROR',
              }}
              placeHolder='인증코드를 입력해주세요'
              onInputChanged={(val) => setVerifyCode(val)}
              type='text'
            />
            <span onClick={onVerifyCodeConfirmClicked} className='confirm-btn'>
              확인
            </span>
          </div>
        )}
        <div className='input-item'>
          <span className='input-label'>비밀번호</span>
          <CInputContainer
            onError={{
              error: false,
              alertMessage: 'THERE IS NO ERROR',
            }}
            placeHolder='비밀번호를 입력해주세요'
            onInputChanged={(val) => {
              setPw(val);
              onPwConfirmNeeded();
            }}
            type='password'
          />
        </div>
        <div className='input-item'>
          <span className='input-label'>비밀번호 확인</span>
          <CInputContainer
            onError={{
              error: needPwConfirm && !passwordConfirmed,
              alertMessage: '비밀번호가 일치하지 않습니다',
            }}
            placeHolder='비밀번호를 한번 더 입력해주세요'
            onInputChanged={(val) => {
              setPwConfirm(val);
            }}
            type='password'
          />
        </div>
        {doneBtnActive ? (
          <span onClick={onDoneBtnClicked} className='done-btn active'>
            회원가입 완료
          </span>
        ) : (
          <span className='done-btn deactive'>회원가입 완료</span>
        )}
      </div>
    </div>
  );
};

export default SignUp;
